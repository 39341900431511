import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MyStaffs.scss";
import { ProgressBarChart } from "../../components/ProgressBarComponent/ProgressBarComponent";
import { Icon } from "@chakra-ui/react";
import project_icon from "../../assets/icons/project-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import image from "../../assets/images/profile-card-cover-image.jpg";
import axios from "axios";
import { fetch_staffs } from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";

export const Staffs = [
	{
		name: "John Smith",
		image: image,
		department: "Engineering",
		checkedin: true,
		phone: "0903902980383",
		date: "today",
	},
];

function MyStaffs() {
	const [loading, setLoading] = React.useState(true);
	const [input, setInput] = useState("");
	const [staffs, setStaffs] = useState([]);
	const [staffDetails, setStaffDetails] = useState(null);
	const [selectedOption, setSelectedOption] = useState("");

	const [cookies] = useCookies();
	const { userProfile } = React.useContext(AuthContext);

	const navigate = useNavigate();

	const fetchStaffs = async () => {
		setLoading(true);
		try {
			const response = await axios.post(
				fetch_staffs,
				{
					company_id: userProfile.company.id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						access: cookies.userToken,
					},
				}
			);
			const { data } = await response.data;
			setStaffs(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		(async () => {
			fetchStaffs();
		})();
	}, [selectedOption]);

	return (
		<div className="MyStaffs xl:px-20 max-md:px-6 md:px-10">
			{staffDetails ? (
				navigate("/dashboard/staffProfile", { state: { staffDetails: staffDetails }, replace: true })
			) : (
				<>
					<div className="mt-7">
						<div className="flex gap-2 items-center mt-4 justify-between">
							<div className="max-w-lg w-full h-12 border bg-white rounded-xl flex items-center p-2 md:pr-4">
								<input
									className="flex-1 h-full rounded-xl border-none outline-none focus:ring-0"
									type="text"
									placeholder="Search staff name"
									value={input}
									onChange={(e) => setInput(e.target.value)}
								/>
								<CiSearch className="h-6 w-6" />
							</div>
							<button
								onClick={() => navigate("/dashboard/add-staff")}
								className="bg-white border hover:bg-gray-200 px-4 p-2 text-native-blue  rounded-xl cursor-pointer active:scale-95 transition-all h-10 block text-sm min-w-fit">
								Add staff
							</button>
						</div>
					</div>
					{loading ? (
						<div>
							{[1, 2, 3, 4, 5, 6].map((i, index) => (
								<div key={index} className="h-12 w-full bg-white animate-pulse py-3 my-2 mt-6"></div>
							))}
						</div>
					) : (
						<div className="mt-6 mx-auto">
							<div className="py-3 text-sm flex justify-between">
								<h2 className="text-2xl font-bold">Employee Table</h2>
								<button className="px-6 py-2 rounded-full border border-gray-400 bg-native-blue text-white active:scale-95 transition-all">
									Export staff attendance
								</button>
							</div>

							<table className="min-w-full border rounded-xl border-gray-300 bg-white">
								<thead className="">
									<tr className="bg-gray-200">
										<th className="py-2 px-4 border-b">Name/Email</th>
										<th className="py-2 px-4 border-b">Phone</th>
										<th className="py-2 px-4 border-b">Join Date</th>
										<th className="py-2 px-4 border-b">Role</th>
									</tr>
								</thead>
								<tbody>
									{staffs
										?.filter((item) => item?.name.toLowerCase().includes(input.toLowerCase()))
										.map((employee, id) => (
											<tr onClick={() => setStaffDetails(employee)} key={employee.id} className="hover:bg-gray-50 cursor-pointer">
												<td className="py-2 px-4 border-b">
													<div>
														<div className="font-semibold">{employee.name}</div>
														<div className="text-gray-500">{employee.email}</div>
													</div>
												</td>
												<td className="py-2 px-4 border-b">{employee.phone_number}</td>
												<td className="py-2 px-4 border-b">{employee.salary_amount}</td>
												<td className="py-2 px-4 border-b">{employee.title}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default MyStaffs;

export function OngoingProjectCard({ project }) {
	// const { project_milestones, project_milestones_completed } = project;
	function calculatePercentage(total, completed) {
		const percentage = (Number(completed) / Number(total)) * 100;

		if (isNaN(Number(percentage))) {
			return 0;
		}
		return Math.round(percentage);
	}
	return (
		<Link to={`./${project?.project_id}`} className="projectCard flex p-4 lg:p-8 justify-between items-center h-28 bg-white rounded-2xl my-3">
			<div>
				<img src={project_icon} />
			</div>
			<div className="w-[35%]">
				<h2 className="text-sm xl:text-xl leading-tight">{project?.project_title}</h2>
				<span>{project?.site_address}</span>
			</div>
			<div>
				<ProgressBarChart
					percentage={calculatePercentage(
						project?.project_status?.project_milestones || 0,
						project?.project_status?.project_milestones_completed || 0
					)}
				/>
			</div>
			<div className="d-flex flex-column">
				<span>Milestones</span> <h1 className="text-[20px]">{project?.project_status.project_milestones}</h1>{" "}
				<span>{project?.project_status.project_milestones_completed + " of " + project?.project_status.project_milestones} milestones completed</span>
			</div>
			<div>
				<Icon as={BsThreeDots} />
			</div>
		</Link>
	);
}

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
	const [active, setActive] = React.useState(0);
	return (
		<div className="FilterScrollComponent flex-col">
			<div className="text-start w-full mt-4">Filter</div>
			<div className="filter-container">
				{filtersArray?.map((filter, i) => (
					<button
						key={i}
						className={active === i ? "active text-sm" : " text-sm"}
						onClick={() => {
							setActive(i);
							handleFilerFunction(i);
						}}>
						{filter}
					</button>
				))}
			</div>
		</div>
	);
}
