import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/AuthPage/LoginPage";
import RegisterPage from "./pages/AuthPage/RegisterPage";
import OtpVerificationPage from "./pages/AuthPage/OtpVerificationPage";
import PasswordResetPage from "./pages/AuthPage/PasswordResetPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import HomePage from "./pages/HomePage/HomePage";
import AddStaff from "./pages/add-staff/add-staff";
import Index from "./pages/schedule/Index.jsx";
import Activities from "./pages/activities/Activities.jsx";
import Assests from "./pages/assetsPage/Assests.jsx";
import SystemSettings from "./pages/systemSettings/SystemSettings.jsx";
import TostMessageComponent from "./components/TostMessageComponent/TostMessageComponent";
import { VerificationProvider } from "./context/VerificationContext";
import { ProjectsProvider } from "./context/ProjectsContext";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import FAQPage from "./pages/FAQPage/FAQPage";
import { useContext, useEffect } from "react";
import { PushNotificationContext } from "./context/PushNotificationContext";
import MyStaffs from "./pages/staff-page/all-staffs";
import EmployeeAttendanceSection from "./pages/Attendance/Attendance";
import Departments from "./pages/departments/departments";
import Procurement from "./pages/procurement/procurement";
import StaffProfile from "./pages/staff-profile/StaffProfile.jsx";

function App() {
	const { isPushNotificationSupported, sendNotification, initializePushNotifications, registerServiceWorker } = useContext(PushNotificationContext);

	useEffect(() => {
		const pushNotificationSuported = isPushNotificationSupported();
		if (pushNotificationSuported) {
			registerServiceWorker();
			initializePushNotifications().then(function (consent) {
				if (consent === "granted") {
					sendNotification();
				}
			});
		}
	}, []);
	return (
		<div>
			<TostMessageComponent />
			<Routes>
				<Route exact path="/" element={<Navigate replace to="/login" />} />
				<Route exact path="/login" element={<LoginPage />} />
				<Route exact path="/register" element={<RegisterPage />} />
				<Route exact path="/verify/:email" element={<OtpVerificationPage />} />
				<Route exact path="/password-reset" element={<PasswordResetPage />} />
				<Route exact path="/dashboard" element={<Navigate replace to="/dashboard/home" />} />
				<Route
					path="/dashboard"
					element={
						<VerificationProvider>
							<ProjectsProvider>
								<Dashboard />
							</ProjectsProvider>
						</VerificationProvider>
					}>
					<Route path="home" element={<HomePage />} />
					<Route path="all-staff" exact element={<MyStaffs />} />
					<Route path="departments" exact element={<Departments />} />
					<Route path="procurements" exact element={<Procurement />} />
					<Route path="schedule" exact element={<Index />} />
					<Route path="activities" exact element={<Activities />} />
					<Route path="staffProfile" exact element={<StaffProfile />} />
					<Route path="systemSettings" exact element={<SystemSettings />} />
					<Route path="assets" exact element={<Assests />} />
					<Route path="attendance" exact element={<EmployeeAttendanceSection />} />
					<Route path="subscriptions/:id" exact element={<Subscriptions />} />
					<Route path="add-staff" element={<AddStaff />} />
					<Route path="staff-profile" element={<StaffProfile />} />
					<Route path="faq" element={<FAQPage />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
