import React, { useCallback } from "react";
import { BidProfileCard, DocumentsCard, MilestoneCard, NotificationCard, TabPanelComponent, TransferCard } from "./DrawerComponent";
import avatar from "../../assets/images/avatar.png";

// for document signature
import { useState } from "react";
import ReactLoading from "react-loading";
import { blobToURL } from "./signature/Utils";
// end of document signature

import check_done_icon from "../../assets/icons/check-done.svg";
import transfer_in from "../../assets/icons/transer_in.svg";
import transfer_out from "../../assets/icons/transfer_out.svg";
import notification_ball from "../../assets/icons/notification-ball.svg";
import MultipleFileUploadComponent from "../MultipleFileUploadComponent/MultipuleFileUploadComponent";
import { TostMessageContext } from "../../context/TostMessage";
import { AuthContext } from "../../context/AuthContext";
import {
	add_milestones_end_point,
	add_to_portfolio_end_point,
	base_url,
	bid_for_project_end_point,
	complete_milestone_end_point,
	download_milestone_report_end_point,
	fetch_bid_details_end_point,
	start_milestone_end_point,
	submit_withdraw_request,
	upload_milestone_csv_endpoint,
	pay_with_wallet_endpoint,
	enable_staff,
	disable_staff,
	get_staff,
} from "../../config/variables";
import { useCookies } from "react-cookie";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { ProjectsContext } from "../../context/ProjectsContext";
import { saveAs } from "file-saver";
import greenTick from "../../assets/icons/green-tick.svg";
import csv_image from "../../assets/images/CSV_TEMP.png";
import { BsArrowLeftCircle, BsTrash, BsCheck2Circle } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import { useContext, useEffect } from "react";
import axios from "axios";
import { LuImagePlus } from "react-icons/lu";
import { Button, Icon, Box, FormLabel, Input, Select, Switch, InputGroup, InputRightElement, Avatar, Divider, useBoolean } from "@chakra-ui/react";
import { WithdrawTabFormComponent } from "../FormsComponent/FormsComponent";
import { useDropzone } from "react-dropzone";

export function PasswordSecuritySettingsTab() {
	const [show, setShow] = React.useState(false);
	const [updatePassword, setUpdatePassword] = React.useState(false);
	const handleClick = () => setShow(!show);
	const handleUpdatePasswordClick = () => setUpdatePassword(!updatePassword);
	return (
		<div className="PasswordSecuritySettingsTab">
			{updatePassword ? (
				<div className="update-password">
					<div className="icon-container">
						<FaArrowAltCircleLeft onClick={() => handleUpdatePasswordClick()} />
					</div>
					<div>
						<b> Current Password</b>
						<p>
							<InputGroup size="md">
								<Input pr="4.5rem" type={show ? "text" : "password"} placeholder="Enter password" />
								<InputRightElement width="4.5rem">
									<Button h="1.75rem" size="sm" onClick={handleClick}>
										{show ? "Hide" : "Show"}
									</Button>
								</InputRightElement>
							</InputGroup>
						</p>
					</div>
					<div>
						<b> New Password</b>
						<p>
							<InputGroup size="md">
								<Input pr="4.5rem" type={show ? "text" : "password"} placeholder="Enter password" />
								<InputRightElement width="4.5rem">
									<Button h="1.75rem" size="sm" onClick={handleClick}>
										{show ? "Hide" : "Show"}
									</Button>
								</InputRightElement>
							</InputGroup>
						</p>
					</div>
					<div>
						<b> Confirm New Password</b>
						<p>
							<InputGroup size="md">
								<Input pr="4.5rem" type={show ? "text" : "password"} placeholder="Enter password" />
								<InputRightElement width="4.5rem">
									<Button h="1.75rem" size="sm" onClick={handleClick}>
										{show ? "Hide" : "Show"}
									</Button>
								</InputRightElement>
							</InputGroup>
						</p>
					</div>
					<div className="mt-auto">
						<button className="submit-button">Update Password</button>
					</div>
				</div>
			) : (
				<>
					<b className="d-flex gap-2 align-items-center">
						Password
						<div className="icon_container" onClick={() => handleUpdatePasswordClick()}>
							<svg
								stroke="currentColor"
								fill="currentColor"
								strokeWidth="0"
								viewBox="0 0 24 24"
								height="1em"
								width="1em"
								xmlns="http://www.w3.org/2000/svg">
								<g>
									<path fill="none" d="M0 0h24v24H0z"></path>
									<path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"></path>
								</g>
							</svg>
						</div>{" "}
					</b>
					<p>
						<InputGroup size="md">
							<Input pr="4.5rem" type={show ? "text" : "password"} placeholder="Enter password" />
							<InputRightElement width="4.5rem">
								<Button h="1.75rem" size="sm" onClick={handleClick}>
									{show ? "Hide" : "Show"}
								</Button>
							</InputRightElement>
						</InputGroup>
						<sub>We will only ask for your urbex password if we need to verify your identity</sub>
					</p>
					<div>
						<b>Biometrics</b>
						<div className="g-card">
							<p>Face or fingerprint</p> <Switch />
						</div>
						<sub>Use face recognition or fingerprint ID instead of a password to authenticate in the mobile app</sub>
					</div>
					<div>
						<b>Text Message</b>
						<div className="g-card">
							<p>Receive a six digit code by text message to confirm it’s you</p> <Switch />
						</div>
					</div>
				</>
			)}
		</div>
	);
}
export function StatsSettingsTab() {
	return (
		<div className="StatsSettingsTab">
			<b> Client Satisfaction</b>
			<p></p>
			<b> Project Execution Rate</b>
			<p>98%</p>
			<b> Projects Executed</b>
			<p>15</p>
			<b>Ongoing Projects</b>
			<p>2</p>
			<b> Amount Earned</b>
			<p>N19,000,0000</p>
		</div>
	);
}
export function PrivacyPolicySettingsTab() {
	return (
		<div className="PrivacyPolicySettingsTab">
			<b>1. Types data we collect</b>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
			</p>
			<b>2. Use of your personal data</b>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
				veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
				velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
			</p>
			<b>3. Disclosure of your personal data</b>
			<p>
				At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas
				molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et
				dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
				impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et
				aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum
				hic tenetur a sapiente delectus
			</p>
		</div>
	);
}
export function LanguageSettingsTab() {
	return (
		<div className="LanguageSettingsTab">
			<b>Suggested</b>
			<label className="radio_container ">
				English (US) <input type="radio" name="language" size="md" /> <span className="checkmark"></span>
			</label>
			<label className="radio_container ">
				English (UK) <input type="radio" name="language" size="md" /> <span className="checkmark"></span>
			</label>
			<b className="d-block my-3">Others</b>
			<label className="radio_container ">
				Hausa <input type="radio" name="language" size="md" /> <span className="checkmark"></span>
			</label>{" "}
			<label className="radio_container ">
				Igbo <input type="radio" name="language" size="md" /> <span className="checkmark"></span>
			</label>
			<label className="radio_container ">
				Yoruba <input type="radio" name="language" size="md" /> <span className="checkmark"></span>
			</label>
		</div>
	);
}
export function NotificationSettingsTab() {
	return (
		<div className="NotificationSettingsTab">
			<b>Common</b>
			<div className="d-flex justify-content-between my-3">
				General Notification <Switch size="md" />
			</div>
			<div className="d-flex justify-content-between my-3">
				Sound <Switch size="md" />
			</div>
			<div className="d-flex justify-content-between my-3">
				Vibrate <Switch size="md" />
			</div>{" "}
			<b className="d-block my-3">System & services update</b>
			<div className="d-flex justify-content-between my-3">
				App updates <Switch size="md" />
			</div>
			<div className="d-flex justify-content-between my-3">
				Payment Request <Switch size="md" />
			</div>
		</div>
	);
}
export function PortfolioDetailesTab({ project }) {
	return (
		<div className="PortfolioDetailesTab">
			<b>Project Description</b>
			<p>{project?.project_description}</p>
			<div className="portfolio-image-container">
				{project?.files?.map((file, i) => (
					<div>
						<img src={file?.file_name} alt="Project " />
						<sup>uploaded: {file?.time_added} </sup>
					</div>
				))}
			</div>

			<b>Posted: {project?.time_added}</b>
		</div>
	);
}
export function NotificationTab({ notifications }) {
	return (
		<div className="">
			{!notifications.length && <div>Your notifications will appear hear</div>}
			{notifications?.map((notification) => {
				return <NotificationCard data={notification} />;
			})}
		</div>
	);
}

export function ViewContractorApplication({ bid_id, setBidId }) {
	const { id } = useParams();
	const { userProfile } = React.useContext(AuthContext);
	const [cookies] = useCookies();
	const [project, setProject] = React.useState({
		loading: true,
		data: {},
	});
	async function getContractorBidFunction() {
		const data = {
			user: {
				account_id: userProfile?.account_id,
			},
			data: {
				bid_id: bid_id,
				project_id: id,
			},
		};
		try {
			const response = await axios.post(fetch_bid_details_end_point, data, {
				headers: {
					access: cookies.userToken,
				},
			});
			setProject({ loading: false, data: response.data });
		} catch (error) {}
	}
	useEffect(() => {
		if (bid_id === null) return;
		getContractorBidFunction();
	}, [bid_id]);

	return (
		<div className="ViewContractorApplication">
			<button onClick={() => setBidId(null)}>
				<BsArrowLeftCircle className="close-button" />
			</button>
			<div className="container">
				{project.loading ? (
					<div className="Loading_screen">Loading...</div>
				) : (
					<div>
						<div className="profile-card">
							<div className="profile-image-container">
								<Avatar src={project.data?.contractor_display_picture} />
							</div>
							<div className="info-side">
								<h2>{project?.data?.contractor_name}</h2>
								<Button> {project?.data?.kyc_stage}</Button>
								<h3>About the contractor</h3>
								<p>
									{project.data?.state}, {project.data?.country}.
								</p>
								<p>
									<b>Applied</b> {project?.data?.date}
								</p>
							</div>
						</div>

						<div className="bid_proposal">
							<h2>Proposal</h2>
							<p className="">{project.data?.bid_proposal}</p>
						</div>
						<div className="project-info-card">
							<Divider className="my-3" />
							<div className="project-info-card-body">
								<h2>Project Milestones</h2>
								<div className="project-milestones-container">
									{project?.data?.bid_milestones?.length <= 0 ? (
										<>No project milestones available</>
									) : (
										<>
											{project?.data?.bid_milestones?.map((milestone) => (
												<div className="milestone d-flex gap-3" key={milestone?.milestone_id}>
													<div className="icon">
														<img src={greenTick} alt="tick icon" />
													</div>
													<div className="text">
														{milestone?.milestone_name}
														<b className="ms-auto me-2"> {milestone?.duration}</b>
													</div>
												</div>
											))}
										</>
									)}
								</div>
								<h2 className="mt-5">Gallery</h2>
								<div className="gallery-container container">
									<div className="row">
										{project?.data?.bid_files?.length <= 0 ? (
											<>No Image for this project</>
										) : (
											<>
												{project?.data?.bid_files?.map((image, i) => (
													<div className="col-4 col-md-3" key={i}>
														<div className="image-container">
															<img src={image} alt="" />
														</div>
													</div>
												))}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export function DocumentsTab({ documents }) {
	const [viewDocument, setViewDocument] = useState(null);
	const [pdf, setPdf] = useState(null);

	const [cookies] = useCookies();
	const [uploading, setUploading] = useState(false);
	const [uploadPercentage, setPercentage] = useState(0);
	const { setTostMessage } = useContext(TostMessageContext);

	const uploadProgress = (progressEvent) => {
		var Percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
		setPercentage(Percentage);
		console.log(progressEvent.loaded, progressEvent.total);
		console.log("Upload progress: " + Math.round((progressEvent.loaded / progressEvent.total) * 100) + "%");
	};

	async function uploadSignedPDF(file) {
		console.log({ file });
		const formData = new FormData();
		setUploading(true);
		formData.append("project_id", viewDocument?.project_id);
		formData.append("document_id", viewDocument?.document_id);
		formData.append("document_name", viewDocument?.document_name);
		formData.append("isSigning", "yes");
		formData.append("signedFile", file);

		try {
			const response = await axios.post(`${base_url}/projects/share_documents.php`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					access: cookies.userToken,
				},
				onUploadProgress: uploadProgress,
			});
			const resData = await response.data;
			console.log({ resData, cookies });
			if (resData.statusCode === 401) {
				setTostMessage({
					messageType: "error",
					message: "signature failed! try again",
				});
			}
			if (resData.status) {
				setTostMessage({
					messageType: "success",
					message: `${viewDocument?.document_name} updated successfully!`,
				});
			}
			setUploading(false);
			setPercentage(0);
		} catch (e) {
			console.log(e);
		}
	}

	const onDrop = useCallback(async (acceptedFiles) => {
		const URL = await blobToURL(acceptedFiles[0]);
		uploadSignedPDF(URL);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: "application/pdf",
	});

	return (
		<div className="">
			{viewDocument ? (
				<div className="">
					<BsArrowLeftCircle
						onClick={() => {
							setViewDocument(null);
						}}
						size={24}
						className="close-button ml-7 mt-10 hover:opacity-60"
					/>
					<div className="m-10  bg-gray-100 p-4 rounded-3xl">
						<p className="font-bold text-lg text-black text-start">{viewDocument.document_name}</p>
						{viewDocument.is_document_signed ? (
							<p>This document contains agreements signed by you</p>
						) : (
							<p>This document is yet to be signed. use the button below to download this file and sign the document.</p>
						)}

						<div className="flex justify-between">
							<button
								onClick={() => saveAs(viewDocument.document_uri, viewDocument.file_name)}
								className="px-10 rounded-full border  text-black hover:bg-slate-400 py-3">
								Download {!viewDocument.is_document_signed && "and sign document"}
							</button>
						</div>
						{!viewDocument.is_document_signed && (
							<div className="mt-10">
								<p>Signed the document? use the button bellow to upload your signed document</p>
								{uploading ? (
									<div className="w-full max-w-[250px] mt-10 bg-gray-200 rounded-full h-2">
										<div className="bg-[#383a62] h-2 rounded-full transition-all ease-in-out" style={{ width: `${uploadPercentage}%` }}></div>
									</div>
								) : (
									<div {...getRootProps()} className="relative">
										<input {...getInputProps()} className="absolute top-0 right-0 left-0 bottom-0" />

										<button className="px-10 rounded-full border  text-black hover:bg-slate-400 py-3">Upload document</button>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					{documents.length === 0 ? (
						<>No document Submited for this projects</>
					) : (
						<>
							{documents?.map((document, id) => {
								return (
									<div key={id}>
										<DocumentsCard document={document} setViewDocument={setViewDocument} />
									</div>
								);
							})}
						</>
					)}
				</div>
			)}
		</div>
	);
}

export function ApplyForProjectTab({ project, closeTabFunction }) {
	const { setTostMessage } = React.useContext(TostMessageContext);
	const { userProfile } = React.useContext(AuthContext);
	const [cookies] = useCookies();
	const [loading, setLoading] = React.useState(false);
	const [files, setFiles] = React.useState([]);
	const [applicationForm, setApplicationForm] = React.useState({
		proposal: "",
		amount: "",
		duration: [],
	});
	const handelChanges = (e) => {
		setApplicationForm((prev) => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			};
		});
	};
	const handelDurationChanges = (e, index) => {
		let newArray = [...applicationForm.duration];
		if (e.target.name === "duration") {
			newArray[index].duration = e.target.value;
		}
		if (e.target.name === "metric") {
			newArray[index].metric = e.target.value;
		}
	};

	async function handelSubmit() {
		if (applicationForm.proposal === "" || applicationForm.amount === 0) {
			setTostMessage({
				messageType: "error",
				message: "Please Fill Form Correctly ",
			});
			return;
		}
		setLoading(true);
		const formData = new FormData();

		formData.append("project_id", project?.data.project_id);
		formData.append("account_id", userProfile?.account_id);
		formData.append("message", applicationForm.proposal);
		formData.append("milestones", JSON.stringify({ milestones: applicationForm.duration }));
		formData.append("proposal_files", files);

		try {
			const response = await axios.post(bid_for_project_end_point, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					access: cookies.userToken,
				},
			});
			if (response.data.status) {
				// console.log(response.data);
				setLoading(false);
				setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
				closeTabFunction();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}

	React.useEffect(() => {
		// console.log("project_milestones", project?.data);
		setApplicationForm((prev) => {
			return {
				...prev,
				duration: project?.data?.project_milestones,
			};
		});
	}, [project]);
	return (
		<div className="ApplyForProjectTab">
			<h1>{project?.data?.project_title}</h1>
			<sup>
				{project?.data?.site_address}, {project?.data?.state}
			</sup>
			<p className="my-3">{project?.data?.project_desc}</p>
			<div className="my-4">
				{" "}
				<b>Teams</b> <br />
				Client Budget : {project?.data?.budget}
			</div>
			<div className="my-4">
				<b>Proposal</b>
				<textarea className="form-control" id="" cols="10" rows="5" name="proposal" onChange={(e) => handelChanges(e)}></textarea>
			</div>
			<br /> <b>Bid amount</b>
			<Box>
				<FormLabel style={{ fontSize: 13 }} htmlFor="amount">
					Amount
				</FormLabel>
				<Input id="amount" className="w-50" name="amount" onChange={(e) => handelChanges(e)} />
			</Box>
			<br /> <b>Duration</b>
			<div className="row">
				{applicationForm?.duration?.map((milestone, i) => {
					return <MilestonesDurationDateInput milestone={milestone} index={i} key={i} handelDurationChanges={handelDurationChanges} />;
				})}
			</div>
			<div className="upload-file-container my-3">
				<MultipleFileUploadComponent files={files} setFiles={setFiles} />
			</div>
			<Button isLoading={loading} className="submit-button" onClick={() => handelSubmit()}>
				Submit
			</Button>
		</div>
	);
}
export function CreatePortfolioTab({ project, closeTabFunction }) {
	const { setTostMessage } = React.useContext(TostMessageContext);
	const { userProfile } = React.useContext(AuthContext);
	const [cookies] = useCookies();
	const [loading, setLoading] = React.useState(false);
	const [files, setFiles] = React.useState([]);
	const [images, setImages] = React.useState([]);
	const [portfolioForm, setPortfolioForm] = React.useState({
		project_title: "",
		project_description: "",
		project_duration: "",
	});

	const handelChanges = (e) => {
		setPortfolioForm((prev) => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			};
		});
	};

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			const selectedImage = event.target.files[0];
			if (selectedImage.type.startsWith("image/")) {
				setImages((prevImages) => [...prevImages, URL.createObjectURL(selectedImage)]);
			} else {
				alert("Please select an image file.");
			}
		}
	};

	async function handelSubmit() {
		if (portfolioForm.proposal === "" || portfolioForm.amount === 0) {
			setTostMessage({
				messageType: "error",
				message: "Please Fill Form Correctly ",
			});
			return;
		}
		setLoading(true);
		const formData = new FormData();

		// formData.append("project_id", project?.data.project_id);
		formData.append("account_id", userProfile?.account_id);
		formData.append("project_title", portfolioForm.project_title);
		formData.append("project_description", JSON.stringify({ milestones: portfolioForm.project_description }));
		formData.append("project_duration", portfolioForm.project_duration);
		formData.append("proposal_files", files);
		formData.append("isUpdate", true);
		try {
			const response = await axios.post(add_to_portfolio_end_point, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					access: cookies.userToken,
				},
			});
			if (response.data.status) {
				// console.log(response.data);
				setLoading(false);
				setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
				closeTabFunction();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}

	return (
		<div className="px-14">
			<Box>
				<FormLabel htmlFor="project_title">
					<b>Project Title</b>
				</FormLabel>
				<input
					id="project_title"
					name="project_title"
					onChange={(e) => handelChanges(e)}
					className="withdraw-input h-12"
					placeholder="Project title"
				/>
			</Box>

			<div className="my-4">
				<b>Project Description</b>
				<textarea
					className="withdraw-input mt-2 text-xs"
					id=""
					cols="10"
					rows="5"
					name="project_description"
					placeholder={`Good descriptions include the following:
            1. Your clients goal
            2. Details about your expertise to the project
            3. A summary of the project’s success`}
					onChange={(e) => handelChanges(e)}></textarea>
			</div>
			{/* <br /> <b>Duration</b> */}
			<Box>
				<FormLabel htmlFor="project_duration">
					<b>Duration</b>
				</FormLabel>
				<input id="project_duration" className="withdraw-input mt-2 h-12" name="project_duration" onChange={(e) => handelChanges(e)} />
			</Box>
			<div className="mb-10 mt-3">
				<b className="mt-4 flex items-center gap-1">
					Project Images <LuImagePlus size={20} className="rounded-lg " />
				</b>
				<div className="gallery-container container p-0">
					<div className="grid grid-cols-3 grid-flow-row gap-4 mt-4">
						{images.map((image, idx) => (
							<div key={idx} className="h-36 relative">
								<img src={image} alt="" className="h-full w-full rounded-lg transition-all object-cover" />
							</div>
						))}
						<div className="h-36 relative bg-gray-100 rounded-lg hover:scale-95 transition-all flex items-center justify-center">
							<input type="file" onChange={onImageChange} className="h-full w-full absolute bg-transparent opacity-0" accept="image/*" />
							<LuImagePlus size={60} className="rounded-lg " />
						</div>
					</div>
				</div>
			</div>

			<div className="upload-file-container my-3">
				<b className="mt-4 d-block">
					Other files <Icon />
				</b>
				<MultipleFileUploadComponent files={files} setFiles={setFiles} />
			</div>
			<div className="text-xs">
				Project files may include
				<ul>
					<li> Images (.jpg, .gif, .png, up to 10 MB, not more than 400 MB)</li>
					<li> Videos (.mp4, .mov, .webm, .ogm, .ogv, up to 100 MB, 2 video maximum, maximum 60 seconds)</li>

					<li> Documents (.pdf, .docx, .txt up to 10 MB)</li>
				</ul>
			</div>
			<button isLoading={loading} className="px-6 py-2 hover:bg-secondary text-white bg-[#766d89] rounded-full my-10" onClick={() => handelSubmit()}>
				Submit
			</button>
		</div>
	);
}

export function MilestonesDurationDateInput({ milestone, index, handelDurationChanges }) {
	return (
		<div className="col-md-6">
			<Box>
				<FormLabel style={{ fontSize: 13 }} htmlFor="corporate_id_number" className="mt-3">
					How long will this milestone take (Estimated)?
				</FormLabel>
				<Input id="corporate_id_number" name="corporate_id_number" value={milestone?.milestone_name} disabled={true} />
			</Box>
			<div className="d-flex gap-3 justify-content-between">
				<Box>
					<FormLabel style={{ fontSize: 13 }} htmlFor="metric" className="mt-2">
						Metric
					</FormLabel>
					<Select id="metric" defaultValue=" Select Metric" name="metric" className="w-100" onChange={(e) => handelDurationChanges(e, index)}>
						<option value="">Select one...</option>
						<option value="Day">Daily</option>
						<option value="Month">Monthly</option>
						<option value="Year">Yearly</option>
					</Select>
				</Box>
				<Box>
					<FormLabel style={{ fontSize: 13 }} htmlFor="duration" className="mt-2">
						How Long?
					</FormLabel>
					<Input id="duration" name="duration" className="w-100" onChange={(e) => handelDurationChanges(e, index)} />
				</Box>
			</div>
		</div>
	);
}

export function TransferTab({ transfers }) {
	const [selected, setSelected] = React.useState(null);
	const [withdrawals, setWithdrawals] = React.useState([]);
	const [credits, setCredits] = React.useState([]);
	const [cookies] = useCookies();
	React.useEffect(() => {
		let withdrawalsArray = [];
		let creditsArray = [];
		transfers.withdrawals.map((transfer) => {
			withdrawalsArray.push({
				...transfer,
				type: "Withdrawals",
			});
		});
		transfers.credits.map((transfer) => {
			creditsArray.push({
				...transfer,
				type: "Credits",
			});
		});

		setWithdrawals(withdrawalsArray);
		setCredits(creditsArray);
	}, [transfers]);

	// for transaction

	const declineRequest = async () => {
		const response = await axios.post(
			`${base_url}/financials/approve_payout.php`,

			{
				user: {
					account_id: cookies.userId,
				},
				data: {
					action: "DECLINE",
				},
			},
			{
				headers: {
					accept: cookies.userToken,
				},
			}
		);
		const resData = await response.data;
		if (resData.statusCode === 401) {
		}
	};
	return (
		<>
			{selected !== null ? (
				<div className="selected-screen">
					<BsArrowLeftCircle onClick={() => setSelected(null)} className="close-button" />
					<br />
					<b> {selected?.account_name}</b>
					<br />
					<span>₦{selected?.amount}</span>
					<br />

					<span>
						<b>Status:</b> {selected?.status}
					</span>
					<p>
						<b>Narration:</b>
						{selected?.narration === "" ? "No narration provided" : selected?.narration}
					</p>

					{selected?.status === "PENDING" ? (
						<div className="flex gap-x-10 mt-10">
							<button className="border-2 px-16 font-bold py-4 rounded-full hover:border-red-400">Decline</button>
							<button className="border-2 px-16  font-bold py-4 rounded-full hover:border-green-400">Approve</button>
						</div>
					) : null}
				</div>
			) : (
				<TabPanelComponent
					btn1Text={`Credits ${transfers.credits.length}`}
					btn2Text={`Withdrawals ${transfers.withdrawals.length} `}
					tab1Content={
						<>
							{credits?.map((transfer, i) => {
								return <TransferCard key={i} index={i} data={transfer} setSelected={setSelected} icon={transfer_in} />;
							})}
						</>
					}
					tab2Content={
						<>
							{" "}
							{withdrawals?.map((transfer, i) => {
								return <TransferCard key={i} index={i} data={transfer} setSelected={setSelected} icon={transfer_out} />;
							})}
						</>
					}
				/>
			)}
		</>
	);
}
export function MilestoneTab({ milestones, closeTabFunction }) {
	const [selected, setSelected] = React.useState(null);
	const [completedMilestone, setCompletedMilestone] = React.useState([]);
	const [nonCompletedMilestone, setNonCompletedMilestone] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [files, setFiles] = React.useState([]);
	const [cookies] = useCookies();
	const { userProfile } = useContext(AuthContext);
	const { setTostMessage } = React.useContext(TostMessageContext);
	const [latitude, setLatitude] = React.useState(null);
	const [longitude, setLongitude] = React.useState(null);
	const [milestoneRemark, setMilestoneRemark] = React.useState("");
	const [error, setErr] = useState("");

	React.useEffect(() => {
		// console.log("milestones", milestones);
		let completedArray = [];
		let nonCompletedArray = [];
		milestones?.map((milestone, i) => {
			if (milestone?.complete) {
				completedArray.push(milestone);
			} else {
				nonCompletedArray.push(milestone);
			}
		});
		setCompletedMilestone(completedArray);
		setNonCompletedMilestone(nonCompletedArray);
	}, [milestones]);

	const { id } = useParams();
	const { getProjectDetailes } = React.useContext(ProjectsContext);

	const startMilestoneFunction = async () => {
		setLoading(true);
		const body = {
			user: {
				account_id: userProfile?.account_id,
			},
			data: {
				project_id: id,
				milestone_id: selected.milestone_id,
			},
		};

		try {
			const response = await axios.post(start_milestone_end_point, body, {
				headers: {
					"Content-Type": "application/json",
					access: cookies.userToken,
				},
			});

			if (response.data.status) {
				setSelected(null);
				setLoading(false);
				getProjectDetailes();
				return setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
			}
			setLoading(false);
			setTostMessage({
				messageType: "error",
				message: "Starting Milestone not sussfully",
			});
		} catch (error) {
			setLoading();
			console.log(error);
			setTostMessage({
				messageType: "error",
				message: "Error Starting Milestone",
			});
		}
	};

	React.useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
				},
				(error) => {
					console.error("Error getting location:", error);
				}
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
		}
	}, []);

	const submitMilestoneRemarkFunction = async () => {
		setLoading(true);
		if (!milestoneRemark) {
			setErr("Please provide milestone remark");
			setLoading(false);
			return;
		}
		const currentPosition = {
			latitude,
			longitude,
		};
		const formData = new FormData();
		formData.append("account_id", userProfile?.account_id);
		formData.append("project_id", id);
		formData.append("milestone_id", selected.milestone_id);
		formData.append("remarks", milestoneRemark);
		formData.append("currentPosition", currentPosition);

		files?.map((item) => {
			return formData.append("files[]", {
				uri: item.uri,
				name: item.name,
				type: item.type,
				size: item.size,
			});
		});

		try {
			const response = await axios.post(complete_milestone_end_point, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					access: cookies.userToken,
				},
			});
			console.log(response.data);
			if (response.data.status) {
				setSelected(null);
				getProjectDetailes();
				setLoading(false);
				setMilestoneRemark("");
				setFiles([]);
				return setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
			}
			setLoading(false);
			setTostMessage({
				messageType: "error",
				message: "Finishing Milestone not sussfully",
			});
		} catch (error) {
			setLoading(false);
			console.log(error);
			setTostMessage({
				messageType: "error",
				message: "Error Finishing Milestone",
			});
		}
	};

	const downloadMilestoneReportFunction = async () => {
		console.log({ download_milestone_report_end_point });
		console.log({ selected });
		console.log(`${download_milestone_report_end_point}?project_id=${id}&milestone_id=${selected.milestone_id}`);
		try {
			const fileName = `${selected.milestone.replace(/\s/g, "_").toUpperCase()}_REPORT`;

			const response = await fetch(`${download_milestone_report_end_point}?project_id=${id}&milestone_id=${selected.milestone_id}`, {
				method: "GET",
			});

			if (response.ok) {
				const blob = await response.blob();
				saveAs(blob, fileName);
				setSelected(null);
			} else {
				setLoading(false);
				setTostMessage({
					messageType: "error",
					message: "Failed to download report",
				});
				throw new Error("Failed to Download Report");
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			setTostMessage({
				messageType: "error",
				message: "Error Downloading Milestone Report",
			});
		}
	};

	return (
		<div className="">
			{selected ? (
				<div className="selected-screen">
					<BsArrowLeftCircle onClick={() => setSelected(null)} className="close-button" />
					<br />
					<span className="span">{selected?.milestone}</span>
					<br />
					<p className="p">
						<b className="b">Milestone Description: </b>
						{selected?.milestone_description === "" ? "No Description provided" : selected?.milestone_description}
					</p>
					{selected?.milestone_started & !selected.complete ? (
						<>
							<b className="mb-2">Enter milestone remarks</b>
							<textarea
								name=""
								id=""
								rows="4"
								className="form-control"
								value={milestoneRemark}
								onChange={(e) => setMilestoneRemark(e.target.value)}></textarea>
							<div className="upload-file-container my-3">
								<MultipleFileUploadComponent files={files} setFiles={setFiles} />
							</div>
						</>
					) : null}
					<div className="">
						{loading ? (
							<>
								<button className="px-14 py-1 rounded-full bg-native-blue" disabled={loading}>
									<ReactLoading className="w-full" type={"cylon"} color="#f3f3f3" />
								</button>
							</>
						) : (
							<>
								{selected?.milestone_started & !selected.complete ? (
									<button
										className="px-14 text-white py-3 rounded-full bg-native-blue"
										onClick={() => submitMilestoneRemarkFunction()}
										disabled={loading}>
										Finish Milestone
									</button>
								) : null}
							</>
						)}

						{selected?.milestone_started & selected.complete ? (
							<button
								className="hover:bg-native-blue hover:text-white py-2 px-5 rounded-full transition-all first-letter:bg-transparent border-native-blue border"
								onClick={() => downloadMilestoneReportFunction()}
								disabled={loading}>
								Download Report
							</button>
						) : null}
						{!selected?.milestone_started ? (
							<button className="approve ms-auto" onClick={() => startMilestoneFunction()} disabled={loading}>
								{loading ? "Loading..." : "Start Milestone"}
							</button>
						) : null}
					</div>
				</div>
			) : (
				<TabPanelComponent
					btn1Text={`${completedMilestone.length} Accomplished`}
					btn2Text={`${nonCompletedMilestone.length} Pending `}
					tab1Content={
						<>
							{completedMilestone.length < 0 ? (
								<>No Record</>
							) : (
								<>
									{completedMilestone?.map((milestone, i) => {
										return <MilestoneCard setSelected={setSelected} key={i} data={milestone} />;
									})}
								</>
							)}
						</>
					}
					tab2Content={
						<>
							{nonCompletedMilestone.length < 0 ? (
								<>No Record</>
							) : (
								<>
									{nonCompletedMilestone?.map((milestone, i) => {
										// console.log({ milestone });
										return <MilestoneCard setSelected={setSelected} key={i} data={milestone} />;
									})}
								</>
							)}
						</>
					}
				/>
			)}
		</div>
	);
}

export function WithdrawTab({ closeTabFunction }) {
	const { id } = useParams();
	const { userProfile } = useContext(AuthContext);
	const [cookies] = useCookies();
	const { setTostMessage } = useContext(TostMessageContext);

	function requestWithdrawalFunction(e) {
		e.preventDefault();

		const formElement = e.target;
		if (
			formElement[0].value === "" ||
			formElement[4].value === "" ||
			formElement[1].value === "" ||
			formElement[2].value === "" ||
			formElement[3].value === ""
		) {
			return setTostMessage({
				messageType: "error",
				message: "Fill Form before submission",
			});
		}
		let data = JSON.stringify({
			user: {
				account_id: userProfile?.account_id,
			},
			data: {
				project_id: id,
				wallet_id: 1,
				amount: formElement[0].value,
				narration: formElement[4].value,
				destination_account_number: formElement[1].value,
				destination_account_name: formElement[2].value,
				destination_bank_code: formElement[3].value,
			},
		});

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: submit_withdraw_request,
			headers: {
				access: cookies.userToken,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				const res = response.data;
				closeTabFunction();
				if (res.status) {
					setTostMessage({
						messageType: "success",
						message: res.response,
					});
				}
			})
			.catch((error) => {
				setTostMessage({
					messageType: "error",
					message: "Error Making Request",
				});
				console.log(error);
			});
	}
	return (
		<>
			<div className="mt-20 px-10">
				<WithdrawTabFormComponent requestWithdrawalFunction={requestWithdrawalFunction} />
			</div>
		</>
	);
}

export function DepositTab({ closeTabFunction, projectDetailes }) {
	console.log({ projectDetailes });
	return (
		<div className="px-10 py-20">
			<b>You can deposit to your project account using the details bellow and it will reflect in your project financials</b>

			<div className="mt-7">
				<FormLabel style={{ fontSize: 15 }} htmlFor="id_file">
					<b>BANK NAME:</b>
				</FormLabel>
				<input placeholder="Enter a Reason" className="withdraw-input max-w-sm" value={projectDetailes.account_bank} disabled />
			</div>
			<div className="mt-4">
				<FormLabel style={{ fontSize: 15 }} htmlFor="id_file">
					<b>ACCOUNT NAME:</b>
				</FormLabel>
				<input placeholder="Enter a Reason" className="withdraw-input max-w-sm" disabled value={projectDetailes.account_name} />
			</div>
			<div className="mt-4">
				<FormLabel style={{ fontSize: 15 }} htmlFor="id_file">
					<b>ACCOUNT NUMBER:</b>
				</FormLabel>
				<input placeholder="Enter a Reason" className="withdraw-input max-w-sm" disabled value={projectDetailes.account_number} />
			</div>
		</div>
	);
}

export function GalleryTab() {
	const [cookies] = useCookies();
	const [images, setImages] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [loading, setLoading] = useState(true);
	const { userProfile } = React.useContext(AuthContext);
	const { id } = useParams();

	const handleClick = (image) => {
		setSelectedImage(image);
	};
	const handleClose = () => {
		setSelectedImage(null);
	};

	const getProjectGallery = async () => {
		try {
			const response = await axios.post(
				`${base_url}/projects/project_gallery.php`,
				{
					user: {
						account_id: userProfile?.account_id,
					},
					data: {
						project_id: id,
					},
				},
				{
					headers: {
						access: cookies.userToken,
					},
				}
			);
			const data = await response.data;
			if (data.status) {
				setImages(data.gallery);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getProjectGallery();
	}, []);

	return (
		<div className="px-7 mt-4">
			<div className="mx-auto">
				<div className="grid grid-cols-2 gap-4">
					{loading ? (
						<>
							{[1, 2, 3, 4, 5, 6, 6, 7].map((i, idx) => (
								<div key={idx} className="h-[200px] w-full rounded-2xl animate-pulse bg-gray-100"></div>
							))}
						</>
					) : (
						<>
							{images.map((image, index) => {
								return (
									<div key={index} className="relative" onClick={() => handleClick(image)}>
										<div className="hover:bg-black rounded-2xl hover:bg-opacity-25 absolute top-0 right-0 left-0 bottom-0 flex">
											<p className="m-auto px-6 py-1 rounded-lg font-semibold bg-opacity-50 cursor-pointer bg-gray-200">View</p>
										</div>
										<img src={image?.file_uri} alt={`Image ${index + 1}`} className="cursor-pointer h-[200px] object-cover w-full rounded-2xl" />
									</div>
								);
							})}
						</>
					)}

					{selectedImage && (
						<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75" onClick={handleClose}>
							<div className="rounded-xl p-3 pt-4 bg-white shadow-lg">
								<button className="text-black text-4xl rounded-xl px-5py-2" onClick={handleClose}>
									x
								</button>
								<img src={selectedImage?.file_uri} alt="Selected Image" className="max-h-[700px] rounded-xl mx-4" />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export function DownloadReportTab({ reports }) {
	const filterReports = () => {
		const filteredReports = reports.filter((item) => item.milestone_started);
		return filteredReports;
	};
	const [selected, setSelected] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const { setTostMessage } = React.useContext(TostMessageContext);

	const { id } = useParams();
	console.log({ selected });

	const data = filterReports();
	useEffect(() => {
		setSelected(data);
		return () => null;
	}, []);

	const downloadMilestoneReportFunction = async (item) => {
		console.log(item);
		try {
			const fileName = `${item.milestone.replace(/\s/g, "_").toUpperCase()}_REPORT`;

			const response = await fetch(`${download_milestone_report_end_point}?project_id=${id}&milestone_id=${item.milestone_id}`, {
				method: "GET",
			});

			if (response.ok) {
				const blob = await response.blob();
				saveAs(blob, fileName);
			} else {
				setLoading(false);
				setTostMessage({
					messageType: "error",
					message: "Failed to download report",
				});
				throw new Error("Failed to Download Report");
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			setTostMessage({
				messageType: "error",
				message: "Error Downloading Milestone Report",
			});
		}
	};

	return (
		<div className="flex flex-col">
			{loading ? (
				<>
					{[1, 2, 3, 4, 5, 6, 7].map((i, idx) => (
						<div key={idx} className="animate-pulse py-10 transition-all ease-in-out cursor-pointer bg-gray-200 my-2 rounded-xl"></div>
					))}
				</>
			) : (
				<div>
					<div className="gap-4 overflow-auto p-3">
						{selected.map((item, i) => {
							const milestone_metric_target_covered = Number(item.milestone_metric_target_covered);
							const milestone_metric_target = Number(item.milestone_metric_target);
							const percentageNum = (milestone_metric_target_covered / milestone_metric_target) * 100;
							const percentage = isNaN(percentageNum) ? 1 : Math.ceil(percentageNum);

							return (
								<div key={i} className="transition-all ease-in-out cursor-pointer my-2 p-2 rounded-xl">
									<div className="flex items-center justify-between">
										<div className="w-[65%]">{item.milestone}</div>
										<div className="flex gap-2 items-center justify-between">
											<div
												className={`text-sm ${item.milestone_started && !item.complete && "bg-blue-700 text-white rounded-full px-2 py-1"}
                         ${item.complete && "bg-emerald-600 text-white rounded-full px-2 py-1"}
                         `}>
												{item.milestone_started && !item.complete ? "In Progress" : item.milestone_started && item.complete ? "Completed" : ""}
											</div>
											<button
												className={`active:scale-95 transition-all ease-in-out text-sm ${
													item.milestone_started && !item.complete
														? "text-blue-600 rounded-full px-2 py-1"
														: item.milestone_started && item.complete
														? "text-emerald-600 rounded-full px-2 py-1"
														: ""
												}`}
												onClick={() => downloadMilestoneReportFunction(item)}>
												Download
											</button>
										</div>
									</div>
									{item.complete && item.milestone_started && <div className="text-xs">Time taken: {item.duration}</div>}
									{!item.milestone_started && <div className="text-xs">Duration: Not started</div>}
									{item.milestone_started && !item.complete && (
										<>
											<div className="text-xs">Duration set: {item.duration}</div>
											<div className="text-xs">Duration elapsed: {item.duration_elapsed}</div>
										</>
									)}
									<div className="flex justify-between mb-1 mt-2">
										<span
											className={`text-xs font-medium  dark:text-white  ${
												item.milestone_started && !item.complete ? "text-blue-600" : item.milestone_started && item.complete ? "text-emerald-600" : ""
											}`}>
											{item.milestone_metric_target_covered} of {item.milestone_metric_target} {item.milestone_metric_system}
										</span>
									</div>
									<div className="w-full bg-gray-100 rounded-full h-2">
										<div
											className={`h-2 rounded-full ${
												item.milestone_started && !item.complete ? "bg-blue-700" : item.milestone_started && item.complete ? "bg-emerald-600" : ""
											}`}
											style={{ width: `${percentage}%` }}></div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}

			{!selected.length && (
				<div className="h-full mx-auto mt-56 items-center">
					<p>Reports submitted for this project will be available here</p>
				</div>
			)}
		</div>
	);
}

export function BidsTab({ project }) {
	const [bidId, setBidId] = React.useState(null);
	console.log({ project });
	React.useEffect(() => {
		console.log("project", project);
	}, [project]);

	return (
		<div className="BidsTab">
			{!bidId ? (
				<>
					{project?.project_bids?.map((bid) => {
						return <BidProfileCard key={bid.bid_id} bid={bid} setBidId={setBidId} />;
					})}
				</>
			) : (
				<ViewContractorApplication bid_id={bidId} setBidId={setBidId} />
			)}

			{!project?.project_bids.length && <div className="mt-10 text-lg">Project Bids will appear here</div>}
		</div>
	);
}
export function ProcurementTab({ procurement }) {
	const [bidId, setBidId] = React.useState(null);
	console.log({ procurement });

	return (
		<div className="BidsTab">
			<div className="mt-10 text-lg">Project Procurements will appear here</div>
		</div>
	);
}

export function StaffTab({ staff }) {
	const [isEnabled, setStatus] = React.useState(null);
	const [cookies] = useCookies();
	const { userProfile } = React.useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [fetchLoading, setFetchLoading] = useState(true);
	const [currStaff, setCurrStaff] = useState(null);

	useEffect(() => {
		(async () => {
			setFetchLoading(true);
			try {
				const response = await axios.post(
					get_staff,
					{
						company_id: userProfile.company.id,
						staff_id: staff.id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							access: cookies.userToken,
						},
					}
				);

				const { data } = await response.data;
				setCurrStaff(data);
				setFetchLoading(false);
			} catch (error) {
				setFetchLoading(false);
			}
		})();
	}, []);

	const enableStaff = async () => {
		setLoading(true);
		try {
			const response = await axios.post(
				enable_staff,
				{
					company_id: userProfile.company.id,
					staff_email: staff.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						access: cookies.userToken,
					},
				}
			);

			const { data } = await response.data;
			setCurrStaff(data);

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};
	const disableStaff = async () => {
		setLoading(true);
		try {
			const response = await axios.post(
				disable_staff,
				{
					company_id: userProfile.company.id,
					staff_email: staff.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						access: cookies.userToken,
					},
				}
			);

			const { data } = await response.data;
			setCurrStaff(data);

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<div className="BidsTab">
			{fetchLoading ? (
				<div className="w-full">
					<div className="h-20 w-20 rounded-full bg-gray-200 animate-pulse mt-10" alt="" />
					<div className="mt-3">
						<p className="m-0 bg-gray-200 h-10 mb-3 w-2/3 font-medium"></p>
						<p className="m-0 bg-gray-200 h-10 mb-3 w-8/12 font-medium"></p>
						<hr className="mt-7 mb-4 border-gray-400" />

						<button onClick={disableStaff} className="px-10 py-3 h-14 rounded-xl bg-gray-200 text-white"></button>
					</div>
				</div>
			) : (
				<div className={`w-full ${currStaff.status == "0" ? "opacity-40" : "opacity-100"}`}>
					<img src={avatar} className="h-20 w-20 rounded-full mt-10" alt="" />
					<div className="mt-3">
						<p className="m-0 text-lg font-bold">Name: {currStaff?.name}</p>
						<p className="m-0 text-sm font-medium mt-4">Email: {currStaff?.email}</p>
						<p className="m-0 text-sm font-medium">Title: {currStaff?.title}</p>
						<p className="m-0 text-sm font-medium">Phone: {currStaff?.phone_number}</p>
						<hr className="mt-7 mb-4 border-gray-400" />
						<div className="flex flex-col justify-between gap-10 items-start md:flex-row md:items-start">
							<div className="">
								<p className="m-0 text-sm font-medium">Email: {currStaff?.email}</p>
								<p className="m-0 text-sm font-medium">Current Salary: {currStaff?.salary_amount}</p>
							</div>
							{currStaff?.status === "1" ? (
								<button onClick={disableStaff} className="px-10 py-3 rounded-full bg-red-500 text-white">
									{loading ? "Loading..." : "Deactivate Staff"}
								</button>
							) : currStaff?.status === "0" ? (
								<button onClick={enableStaff} className="px-10 py-3 rounded-xl bg-emerald-400 opacity-100 text-white">
									{loading ? "Loading..." : "Activate Staff"}
								</button>
							) : null}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export function SubscriptionPayCard({ closeTabFunction, plan, price }) {
	const [siteVisits, setSiteVisits] = useState(0);
	const [addOns, setAddOns] = useState(0);
	const { id } = useParams();
	const { projectDetailes, setProjectDetailes } = React.useContext(ProjectsContext);
	const { userProfile } = React.useContext(AuthContext);
	const [cookies] = useCookies();
	const { setTostMessage } = React.useContext(TostMessageContext);
	const [loading, setLoading] = useState(false);
	const [livestream, setLivestream] = useBoolean();
	const [siteVisitsCount, setSiteVisitsCount] = useState(0);

	function SiteVisit(type, addon_cost) {
		if (type === "add") {
			setSiteVisits(siteVisits + 1);
			setAddOns(addOns + addon_cost);
			setSiteVisitsCount(siteVisitsCount + 1);
		} else {
			if (siteVisits > 0) {
				setSiteVisits(siteVisits - 1);
				setAddOns(addOns - addon_cost);
				setSiteVisitsCount(siteVisitsCount - 1);
			}
		}
	}
	function addLiveStream(addon_cost) {
		setLivestream.toggle();
		if (!livestream) {
			setAddOns(addOns + addon_cost);
		} else {
			setAddOns(addOns - addon_cost);
		}
	}
	async function payWithWallet() {
		setLoading(true);
		var total = addOns + price;

		var items = "";
		if (siteVisitsCount > 0) {
			items = items + `${siteVisitsCount} Site Visits,`;
		}
		if (livestream) {
			items = items + ` Livestream setup`;
		}

		const data = {
			user: {
				account_id: userProfile?.account_id,
			},
			data: {
				project_id: id,
				amount: total,
				subscriptions: items,
			},
		};
		try {
			const response = await axios.post(pay_with_wallet_endpoint, data, {
				headers: {
					access: cookies.userToken,
				},
			});
			setLoading(false);
			if (response?.data?.status) {
				setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
				closeTabFunction();
			} else {
				setTostMessage({
					messageType: "error",
					message: response.data.response,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className="">
			<div className="mt-3 flex items-center">
				<div>
					<h2 className="text-[#32a852] text-[50px] font-bold mb-0">{`$${price.toLocaleString("en-us")}`}</h2>
					<span className="text-[15px]">per month</span>
				</div>
				<a className="text-[30px] font-bold mx-2">+</a>
				<div>
					<h2 className="text-[#32a852] text-[50px] font-bold mb-0">{`${addOns.toLocaleString("en-us")}`}</h2>
					<span className="text-[15px]">add-ons</span>
				</div>
			</div>
			<div className="mt-3 mb-3">
				<a className="font-bold">Access more with the urbex app!</a>
				{plan == "Tier 1" && (
					<>
						<div className="px-0">
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Dedicated project manager</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">3 Site visits monthly</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Realtime Milestone & project reports.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Unlimited calls & chats.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Add-on Extra site visits.</a>
							</span>
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center">
							<a className="font-bold">Add extra site visits ($10.00)</a>
							<div className="flex items-center">
								<button onClick={() => SiteVisit("minus", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlineMinus size={15} />
								</button>
								<a className="font-bold text-[20px] mx-2">{siteVisits}</a>
								<button onClick={() => SiteVisit("add", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlinePlus size={15} />
								</button>
							</div>
							{/* <Switch size="md" /> */}
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-1 rounded-[5px] flex justify-between items-center">
							<a className="font-bold text-[#585858]">{`Add site livestream (From Tier 2 & Above)`}</a>
							<Switch size="md" isDisabled={true} />
						</div>
					</>
				)}
				{plan == "Tier 2" && (
					<>
						<div className="px-0">
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Dedicated project manager</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">6 Site visits monthly</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Realtime Milestone & project reports.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Unlimited calls & chats.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Add-on Extra site visits.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Add-on site livestream option.</a>
							</span>
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center">
							<a className="font-bold">Add extra site visits ($10.00)</a>
							<div className="flex items-center">
								<button onClick={() => SiteVisit("minus", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlineMinus size={15} />
								</button>
								<a className="font-bold text-[20px] mx-2">{siteVisits}</a>
								<button onClick={() => SiteVisit("add", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlinePlus size={15} />
								</button>
							</div>
							{/* <Switch size="md" /> */}
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-1 rounded-[5px] flex justify-between items-center">
							<a className="font-bold">{`Add site livestream ($300.00)`}</a>
							<Switch size="md" onChange={() => addLiveStream(300)} colorScheme="green" />
						</div>
					</>
				)}
				{plan == "Tier 3" && (
					<>
						<div className="px-0">
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Dedicated project manager</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">12 Site visits monthly</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Realtime Milestone & project reports.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Unlimited calls & chats.</a>
							</span>
							<span className="flex items-center">
								<BsCheck2Circle color="#32a852" className="mr-1" />
								<a className="text-[13px]">Site livestream option.</a>
							</span>
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center">
							<a className="font-bold">Add extra site visits ($10.00)</a>
							<div className="flex items-center">
								<button onClick={() => SiteVisit("minus", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlineMinus size={15} />
								</button>
								<a className="font-bold text-[20px] mx-2">{siteVisits}</a>
								<button onClick={() => SiteVisit("add", 10)} className="border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue">
									<AiOutlinePlus size={15} />
								</button>
							</div>
							{/* <Switch size="md" /> */}
						</div>
						<div className="w-[80%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-1 rounded-[5px] flex justify-between items-center">
							<a className="font-bold">{`Add extra livestream feeds ($200.00)`}</a>
							<Switch size="md" onChange={() => addLiveStream(200)} colorScheme="green" />
						</div>
					</>
				)}
			</div>
			<hr className="mt-3"></hr>
			<div className="mt-3 flex items-center justify-center flex-column">
				<a className="font-bold">Choose Payment Method</a>
				<div className="flex flex-column items-center">
					<button
						onClick={() => payWithWallet()}
						className="bg-white border-[#000AFF] border-2 text-[#000AFF] text-[20px]  px-4 py-2 font-bold mt-3 h-100 w-100 rounded-[10px]">
						{!loading ? "Pay from project account" : "Processing..."}
					</button>
					<a className="text-[20px] font-bold text-native-blue my-2">OR</a>
					<button className="bg-[#000AFF] border-[#000AFF] border-2 text-white text-[20px]  px-4 py-2 font-bold mt-0 h-100 w-100 rounded-[10px]">
						Pay online
					</button>
				</div>
			</div>
		</div>
	);
}

export function AddMilestones({ closeTabFunction }) {
	const { userProfile } = useContext(AuthContext);
	const { projectDetailes, getProjectDetailes } = React.useContext(ProjectsContext);
	const { id } = useParams();
	const [cookies] = useCookies();
	const { setTostMessage } = useContext(TostMessageContext);
	const [loading, setLoading] = useState(false);
	const [import_file, setImport] = useState(false);
	const [files, setFiles] = React.useState([]);
	const [milestones, setMilestones] = useState([
		{
			milestone_name: "",
			duration: 0,
			metric: "",
			description: "",
			milestone_metric_target: "",
			milestone_metric_system: "",
		},
	]);

	const handelChanges = (index, property, value) => {
		if (property === "delete") {
			const updatedMilestones = milestones.filter((_, i) => i !== index);
			setMilestones(updatedMilestones);
		} else {
			const updatedMilestones = [...milestones];
			updatedMilestones[index] = {
				...updatedMilestones[index],
				[property]: value,
			};
			setMilestones(updatedMilestones);
		}
	};

	async function handelSubmit() {
		setLoading(true);
		const formData = new FormData();

		formData.append("project_id", projectDetailes?.data.project_id);
		formData.append("account_id", userProfile?.account_id);
		console.log(files);
		formData.append("milestone_file", files[0]);

		try {
			const response = await axios.post(upload_milestone_csv_endpoint, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					access: cookies.userToken,
				},
			});
			if (response.data.status) {
				// console.log(response.data);
				setLoading(false);
				setTostMessage({
					messageType: "success",
					message: response.data.response,
				});
				closeTabFunction();
			} else {
				setLoading(false);
				setTostMessage({
					messageType: "error",
					message: response.data.response,
				});
			}
		} catch (error) {
			setLoading(false);
			setTostMessage({
				messageType: "error",
				message: error,
			});
			console.log(error);
		}
	}

	async function AddMilestonesFunction() {
		let error = false;
		milestones.forEach((milestone, i) => {
			if (!milestone.milestone_name || milestone.milestone_name === "") {
				error = true;
				return setTostMessage({
					messageType: "error",
					message: `Fill milestone name in form ${i + 1}`,
				});
			}
			if (!milestone.duration || milestone.duration === "") {
				error = true;
				return setTostMessage({
					messageType: "error",
					message: `Fill duration in form ${i + 1}`,
				});
			}
			if (!milestone.metric || milestone.metric === "") {
				error = true;
				return setTostMessage({
					messageType: "error",
					message: `Fill metric in form ${i + 1}`,
				});
			}
			if (!milestone.milestone_metric_system || milestone.milestone_metric_system === "") {
				error = true;
				return setTostMessage({
					messageType: "error",
					message: `Fill target metric in form ${i + 1}`,
				});
			}
			if (!milestone.milestone_metric_target || milestone.milestone_metric_target === "") {
				error = true;
				return setTostMessage({
					messageType: "error",
					message: `Fill activity target in form ${i + 1}`,
				});
			}
		});

		if (error) {
			return;
		}

		let data = JSON.stringify({
			user: {
				account_id: userProfile?.account_id,
			},
			data: {
				project_id: id,
				milestones,
			},
		});

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: add_milestones_end_point,
			headers: {
				access: cookies.userToken,
				"Content-Type": "application/json",
			},
			data: data,
		};
		setLoading(true);
		// return console.log(config);
		axios
			.request(config)
			.then((response) => {
				setLoading(false);
				if (response.data.status) {
					getProjectDetailes();
					setTostMessage({
						messageType: "success",
						message: response.data.response,
					});
					setMilestones([]);
					closeTabFunction();
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	}
	return (
		<>
			{import_file ? (
				<div className="flex flex-column align-end items-end justify-end">
					<button
						className="px-3 py-1 bg-native-blue text-white rounded-full w-50 mb-2"
						onClick={() => {
							setImport(false);
						}}>
						Enter Manually
					</button>
					<Box>
						<div className="grid grid-cols-1">
							<div className="p-3 rounded-xl bg-gray-50 transition-all ease-in-out my-3 flex items-start gap-4">
								<div className="">
									<div>
										<h5 className="">NOTE:</h5>
										<p className="text-sm text-gray-500">
											You can import project activities as a CSV file to ease up the data entry process. The CSV file should be in the following
											format:
										</p>
									</div>
								</div>
							</div>
							<center>
								<img src={csv_image} className="w-[70%] flex self-center h-auto" />
							</center>
							<div className="upload-file-container my-3">
								<MultipleFileUploadComponent files={files} setFiles={setFiles} supportedFiles={"CSV Files only"} />
							</div>
							<button onClick={() => handelSubmit()} className="bg-[#32a852] p-10 py-3 text-white mx-auto rounded-full w-[40%]">
								{loading ? "Uploading..." : "Import Activities"}
							</button>
						</div>
					</Box>
				</div>
			) : (
				<div className="flex flex-column align-end items-end justify-end">
					<button
						className="px-3 py-1 bg-native-blue text-white rounded-full w-50 mb-2"
						onClick={() => {
							setImport(true);
						}}>
						Import CSV file
					</button>
					{milestones.length === 0 ? null : (
						<>
							<Box>
								<div className="grid grid-cols-1">
									{projectDetailes?.data?.project_milestones_set == "0"
										? projectDetailes?.data?.project_milestones_template?.map((item, i) => {
												return <AddMilestonesInputs index={i} key={i} milestones={item} handelChanges={handelChanges} />;
										  })
										: null}
									{milestones?.map((milestones, i) => {
										return <AddMilestonesInputs index={i} key={i} milestones={milestones} handelChanges={handelChanges} />;
									})}
								</div>
							</Box>
							<div className="flex items-center justify-center mt-3">
								{milestones.length > 0 && (
									<button
										className="p-10 py-3 rounded-full"
										onClick={() => {
											setMilestones([
												...milestones,
												{
													milestone_name: "",
													duration: 0,
													metric: "",
													description: "",
												},
											]);
										}}>
										<a className="text-bold">Add New Activity</a>
									</button>
								)}
								<Box className="d-flex ">
									<button className="bg-[#32a852] p-10 py-3 text-white mx-5 rounded-full" onClick={() => AddMilestonesFunction()} disabled={loading}>
										{loading ? "Loading..." : `Save ${milestones.length} Milestones`}
									</button>
								</Box>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
}

function AddMilestonesInputs({ index, handelChanges, milestones }) {
	const handleChange = (e) => {
		const { name, value } = e.target;
		handelChanges(index, name, value);
	};
	const handleDelete = () => {
		handelChanges(index, "delete");
	};

	return (
		<div className="AddMilestonesInputs border-b bg-gray-100 rounded-xl p-3 m-0 flex justify-between items-center mb-3">
			<div className="w-auto">
				<Box>
					<FormLabel style={{ fontSize: 13 }} htmlFor="milestone_name" className="mt-0">
						Activity Name
					</FormLabel>
					<Input
						id="milestone_name"
						placeholder="Enter activity name"
						disabled={false}
						className="text-10 h-auto py-1 bg-white"
						name="milestone_name"
						value={milestones.milestone}
						onChange={(e) => handleChange(e)}
					/>
				</Box>
				<div className="d-flex gap-3 justify-content-between m-w-[100%]">
					<Box>
						<FormLabel style={{ fontSize: 13 }} htmlFor="metric" className="mt-2">
							Metric
						</FormLabel>
						<Select id="metric" className="w-auto text-10 h-auto py-1 bg-white" name="metric" onChange={(e) => handleChange(e)}>
							<option value="">{milestones.milestone_eta_metric}</option>
							<option value="Day">Day</option>
							<option value="Week">Week</option>
							<option value="Month">Month</option>
							<option value="Year">Year</option>
						</Select>
					</Box>
					<Box>
						<FormLabel style={{ fontSize: 13 }} htmlFor="duration" className="mt-2">
							Duration
						</FormLabel>
						<Input
							id="duration"
							name="duration"
							className="w-100 text-10 h-auto py-1 bg-white"
							value={milestones.milestone_eta}
							onChange={(e) => handleChange(e)}
						/>
					</Box>
					<Box>
						<FormLabel style={{ fontSize: 13 }} htmlFor="metric" className="mt-2">
							Target Metric
						</FormLabel>
						<Select id="metric" className="w-auto text-10 h-auto py-1 bg-white" name="metric" onChange={(e) => handleChange(e)}>
							<option value="">{milestones.milestone_metric_system}</option>
							<option value="Day">Day</option>
							<option value="Week">Week</option>
							<option value="Month">Month</option>
							<option value="Year">Year</option>
						</Select>
					</Box>
					<Box>
						<FormLabel style={{ fontSize: 13 }} htmlFor="duration" className="mt-2">
							Target
						</FormLabel>
						<Input
							id="duration"
							name="duration"
							className="w-100 text-10 h-auto py-1 bg-white"
							value={milestones.milestone_metric_target}
							onChange={(e) => handleChange(e)}
						/>
					</Box>
				</div>
			</div>
			<button onClick={() => handleDelete()} className="delete-button bg-transparent  text-red rounded-full px-3 py-1 h-[50px]">
				<BsTrash className="text-[#ff0000] text-[15px]" />
			</button>
		</div>
	);
}

export function ViewClockIn({ closeTabFunction, selected }) {
	const [loading, setLoading] = useState(false);
	const { userProfile } = useContext(AuthContext);
	const { setTostMessage } = useContext(TostMessageContext);
	const [cookies] = useCookies();

	console.log(`https://maps.google.com/maps?q=${selected?.position_lat},${selected?.position_lon}&hl=en&z=14&amp;output=embed`);

	return (
		<>
			<div className="flex flex-column">
				<b className="text-[16px] font-bold py-2">{`Time: ` + selected?.timestamp}</b>
				<b className="text-[16px] font-bold py-2">{`Device: ` + selected?.androidID}</b>
				<b className="text-[16px] font-bold py-2">{`Location: ` + selected?.location}</b>
			</div>
			<div className="google-map-code mt-3">
				<iframe
					src={`https://www.google.com/maps/embed/?q=${selected?.position_lat},${selected?.position_lon}&hl=en&z=14&amp;output=embed`}
					width="600"
					height="450"
					frameborder="0"
					style={{ border: 0 }}
					allowfullscreen=""
					aria-hidden="false"
					tabindex="0"></iframe>
			</div>
		</>
	);
}
