import clsx from "clsx";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { AuthContext } from "../../context/AuthContext";

export function LoginForm() {
	const [loading, setLoading] = useState(false);

	const { LoginFunction } = useContext(AuthContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const onSubmit = (data) => {
		setLoading(true);

		LoginFunction(data, setLoading);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="">
			<input
				type="text"
				placeholder={"Your email"}
				{...register("email", {
					required: true,
				})}
				className={clsx("input w-full bg-base-100 mt-5 signup-input", {
					["border border-danger"]: errors.email,
				})}
			/>

			<input
				type="password"
				placeholder="Enter password"
				{...register("password", {
					required: true,
				})}
				className={clsx("input w-full bg-base-100 mt-3 signup-input", {
					["border border-danger"]: errors.password,
				})}
			/>

			<div className="mt-4 join join-vertical lg:join-horizontal flex-col flex  justify-between">
				{loading ? (
					<ReactLoading type={"cylon"} color="#172337" />
				) : (
					<button disabled={loading} type="submit" className="bg-[#172337] py-3 mb-2 rounded-lg text-white px-5">
						Login
					</button>
				)}
				<div className="flex flex-col sm:flex-row justify-between items-center mt-2">
					<Link className="font-bold" to={"/password-reset"}>
						Forgot password?
					</Link>
				</div>
			</div>
		</form>
	);
}
