import React, { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Dashboard.scss";
import DashboardSideNav, { DashboardTopNav } from "../../components/Nav/Nav.jsx";
import { AuthContext } from "./../../context/AuthContext";

function Dashboard() {
	const [sideNavOpen, setSideNavOpen] = useState(false);

	const { isLoggedIn } = useContext(AuthContext);

	if (isLoggedIn === false) {
		return (
			<div className="session_expired">
				Login Session Expired Login again <br />
				<Link to={"/login"}>Login</Link>
			</div>
		);
	}

	return (
		<div className="max-h-screen w-full h-full grid lg:grid-cols-11 bg-native-blue">
			<div className="bg-native-blue pr-5 hidden lg:flex flex-col lg:col-span-3 xl:col-span-2 h-full min-h-screen w-full">
				<DashboardSideNav sideNavOpen={sideNavOpen} setSideNavOpen={setSideNavOpen} />
			</div>
			<div className="lg:col-span-8 custom-scrollbar overflow-auto xl:col-span-9 bg-gray-100 lg:rounded-bl-[60px] lg:rounded-tl-[60px] h-screen w-full">
				<DashboardTopNav />
				<div className="pb-10">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
