import React, { useContext, useEffect, useState } from 'react';
import './HomePage.scss';
import AccountSetUpCard from './../../components/AccountSetUpCard/AccountSetUpCard';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { Staffs } from '../staff-page/all-staffs';
import avatar from '../../assets/images/avatar.png';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
function HomePage() {
  const { userProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // console.log("[userProfile:]", userProfile);
  // }, []);
  const { getUserProfileFunction } = useContext(AuthContext);
  useEffect(() => {
    getUserProfileFunction();
  }, []);

  // console.log('persist', userProfile);
  useEffect(() => {
    if (userProfile) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [userProfile]);

  // console.log(userProfile);
  return (
    <div className="HomePage">
      <div className="page-name-container">
        <div>
          <div className="page-name">Dashboard</div>
          <p>
            Hi 👋, welcome
            <b> {userProfile?.name} </b>
            to your HR Urbex dashboard{' '}
          </p>
        </div>
        <div></div>
      </div>
      <div className="">
        <div className="xl:grid xl:grid-cols-7 gap-4 px-4 md:px-10 lg:px-20">
          <div className="md:col-span-4">
            {/* Overview section */}
            {loading ? (
              <div className="border mb-4 w-full rounded-xl p-4">
                <div className="grid my-3 grid-cols-3 gap-4">
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                </div>{' '}
                <div className="grid my-3 grid-cols-3 gap-4">
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                </div>{' '}
                <div className="grid my-3 grid-cols-3 gap-4">
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                </div>
                <div className="grid my-3 grid-cols-3 gap-4">
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                  <div className="bg-gray-200 w-full rounded-lg animate-pulse h-20"></div>
                </div>
              </div>
            ) : (
              <div className="rounded-lg p-4 bg-gray-100 hover:bg-white text-white h-full border mb-4">
                <div className="grid grid-rows-3 h-full gap-4">
                  <div className="flex items-center gap-2 h-full p-3 bg-native-blue text-white border rounded-lg">
                    <h6 className="m-0">Total Staffs:</h6>
                    <h6 className="m-0"> {userProfile.company.total_staffs}</h6>
                  </div>
                  <div className="h-full gap-3 rounded-lg grid grid-cols-2">
                    <div className="flex items-center gap-2 h-full p-3 border bg-native-blue text-white rounded-lg">
                      <h6 className="m-0">Total check-in Today: </h6>
                      <h6 className="m-0">
                        {userProfile.company.total_check_in_today}
                      </h6>
                    </div>
                    <div className="flex items-center gap-2 h-full p-3 border bg-native-blue text-white rounded-lg">
                      <h6 className="m-0">Total Checkout Today:</h6>
                      <h6 className="m-0">
                        {userProfile.company.total_check_out_today}
                      </h6>
                    </div>
                  </div>
                  <div className="h-full gap-3 rounded-lg grid grid-cols-2">
                    <div className="flex items-center gap-2 h-full p-3 border bg-native-blue text-white rounded-lg">
                      <h6 className="m-0">Started Since: </h6>
                      <h6 className="m-0">
                        {' '}
                        {userProfile.company.total_hours_since_start}
                      </h6>
                    </div>
                    <div className="flex items-center gap-2 h-full p-3 border bg-native-blue text-white rounded-lg">
                      <h6 className="m-0">Total Unchecked-out:</h6>
                      <h6 className="m-0">
                        {userProfile.company.total_not_check_out_today}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ActiveStaffs />
        </div>
      </div>
    </div>
  );
}

export default HomePage;

export function ActiveStaffs() {
  const [loading, setLoading] = React.useState(true);
  const [input, setInput] = useState('');
  const [staffs, setStaffs] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const { userProfile } = useContext(AuthContext);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Filter the Staffs array based on the selected option
    const filteredProjects =
      selectedOption === '' // Check if selectedOption is empty string
        ? Staffs // If empty, return the entire Staffs array
        : Staffs.filter((item) => item.date === selectedOption); // Otherwise, filter based on the selected option

    setStaffs(filteredProjects);
    setLoading(false);
  }, [selectedOption]);

  // console.log(userProfile);
  return (
    <div className="b grid xl:col-span-3 rounded-xl pt-2 border hover:bg-white text-black p-2">
      <div className="overflow-x-hidden pt-2">
        <div className="mb-4 flex flex-row justify-between items-center">
          <div className="font-semibold">Staffs</div>
          <Link
            to={'/dashboard/all-staff'}
            className="border py-1 px-2 cursor-pointer rounded-full bg-native-blue text-white text-sm"
          >
            View All
          </Link>
        </div>
        {loading ? (
          <div>
            {[1, 2, 3, 4, 5, 6].map((i, index) => (
              <div
                key={index}
                className="h-24 w-full bg-gray-200 animate-pulse rounded-lg py-3 my-7"
              ></div>
            ))}
          </div>
        ) : (
          <>
            {staffs ? (
              <div className="rounded-2xl bg-white text-black ">
                <dialog className="z-50 modal" id="staff_modal">
                  <div className="modal-box">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                      </button>
                    </form>
                    <h3 className="font-bold text-lg">Hello!</h3>
                    <p className="py-4">
                      Press ESC key or click on ✕ button to close
                    </p>
                  </div>
                </dialog>
                {userProfile.company.recent_staffs_checks
                  .slice(0, 4)
                  .map((item, i) => {
                    return (
                      <MainDrawerComponent
                        project={item}
                        open={20}
                        // className="max-md:w-[100%] md:w-[100%] items-center"
                        card={{
                          sub: 'Staff Details',
                        }}
                        key={i}
                      >
                        <div className="flex cursor-pointer items-center rounded-2xl max-md:px-2 md:px-4 py-3 hover:bg-gray-50 border-t border-gray-100">
                          <dialog className="z-50 modal" id="staff_modal">
                            <div className="modal-box">
                              <form method="dialog" className="h-96 max-w-xl">
                                {/* if there is a button in form, it will close the modal */}
                                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                                  ✕
                                </button>
                              </form>
                              <h3 className="font-bold text-lg">Hello!</h3>
                              <p className="py-4">
                                Press ESC key or click on ✕ button to close
                              </p>
                            </div>
                          </dialog>
                          <div className="flex-1 w-fit flex">
                            <img
                              src={avatar}
                              className="h-12 w-12 rounded-full"
                              alt=""
                            />
                            <div className="ml-5">
                              <h5 className="m-0 text-sm">{item.name}</h5>
                              <div className="md:flex gap-3">
                                <p className="m-0 max-md:text-xs">
                                  {item.department}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-fit flex items-center gap-2">
                            <div className="flex flex-col">
                              <div className="text-xs text-blue-500">
                                Check in:
                              </div>
                              <div className="">{item.check_in}</div>
                            </div>
                          </div>
                        </div>
                      </MainDrawerComponent>
                    );
                  })}
              </div>
            ) : (
              <div className="no-project">
                <h1>You have not created any staffs</h1>
                <Link to="/dashboard/add-staff">Add staffs</Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
