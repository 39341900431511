import React, { useContext, useState } from "react";
import { NavLink, useLocation, useParams, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./DashboardSideNav.scss";
import { DashboardSideNavLinks } from "./navLink";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { TbLogout2 } from "react-icons/tb";
import { BsArrowLeftShort, BsArrowRightShort, BsBellFill, BsFillArrowLeftSquareFill } from "react-icons/bs";
import { Avatar } from "@chakra-ui/react";
import Logo from "../../assets/images/logo.png";
import { ProjectsContext } from "../../context/ProjectsContext";

export default function DashboardSideNav() {
	const { userProfile, logOutFunction } = useContext(AuthContext);
	const location = useLocation();

	return (
		<div className="DashboardSideNav flex flex-col py-4">
			<div className="w-20 mx-auto flex items-center justify-center">
				<img src={Logo} className="h-auto max-w-full" alt="logo" />
			</div>
			<div className="max-h-screen overflow-y-auto flex flex-grow flex-col items-start justify-between relative pt-8">
				<ul className="p-0 flex-grow w-full gap-y-[3px] flex flex-col">
					{DashboardSideNavLinks?.map((link) => {
						const { name, icon: Icon, path, id, accountType } = link;
						const isActive = path == location.pathname;
						return (
							<React.Fragment key={id}>
								{(userProfile?.account_type && userProfile?.account_type.toLowerCase() === accountType.toLowerCase()) || accountType === "both" ? (
									<li>
										<NavLink
											className={`flex justify-between text-lg items-center pr-2 pl-4 py-[7px] transition-[background-color] duration-300 rounded-tr-full rounded-br-full ${
												isActive ? "bg-white text-native-blue" : "text-white hover:bg-white/10"
											}`}
											to={path}>
											<div className="flex items-center gap-x-3.5">
												<div className="side-nav-icon">{<Icon />}</div>
												<span className="nav-link-name text-[.95rem]">{name}</span>
											</div>
											{isActive && (
												<span className="p-[3px] flex items-center justify-center bg-stone-300 rounded-full">
													<BsArrowRightShort className="h-6 w-6" />
												</span>
											)}
										</NavLink>
									</li>
								) : null}
							</React.Fragment>
						);
					})}
				</ul>
				<div
					onClick={() => logOutFunction()}
					className={`flex cursor-pointer justify-between text-lg w-full items-center pr-2 pl-4 py-[7px] transition-[background-color,color] ease-in-out duration-300 rounded-tr-full rounded-br-full text-white hover:bg-red-400 hover:text-white`}>
					<div className="flex items-center gap-x-3.5">
						<TbLogout2 />
						<span className="nav-link-name text-[.95rem]">Log out</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export function DashboardTopNav() {
	const { userProfile, logOutFunction } = React.useContext(AuthContext);
	const [toggleNav, setToggleNav] = React.useState(false);
	const location = useLocation();
	const { projectDetailes, getProjectDetailes } = useContext(ProjectsContext);
	const params = useParams();

	React.useEffect(() => {
		getProjectDetailes();
	}, []);

	return (
		<>
			<div className="h-24 flex items-center justify-between border-b px-6 md:px-10">
				<div className="flex gap-4 items-center">
					<div onClick={() => setToggleNav(true)} className="flex cursor-pointer items-center justify-center lg:hidden">
						<AiOutlineMenu size={24} />
					</div>
					{DashboardSideNavLinks.filter((item) => item.path == location.pathname).map((item, id) => (
						<div key={id}>{location.pathname == item.path && <h2 className="text-2xl m-0">{item.name}</h2>}</div>
					))}
					<div className="flex items-center gap-2">
						{location.pathname == `/dashboard/ongoing-projects/${params.id}` && (
							<>
								<img src="/pajamas_project.svg" className="h-6 w-6" alt="icon" />
								<h2 className="text-2xl m-0 md:hidden">{projectDetailes?.data?.project_title.slice(0, 25)}...</h2>
								<h2 className="text-2xl m-0 max-md:hidden">{projectDetailes?.data?.project_title}</h2>
							</>
						)}
						{location.pathname == `/dashboard/subscriptions/${params.id}` && (
							<>
								<h2 className="text-2xl m-0 md:hidden">{projectDetailes?.data?.project_title.slice(0, 25)}...</h2>
								<h2 className="text-2xl m-0 max-md:hidden">{projectDetailes?.data?.project_title}</h2>
							</>
						)}
					</div>
				</div>

				<div className="flex items-center justify-end gap-4">
					{location.pathname == `/dashboard/ongoing-projects/${params.id}` && (
						<Link
							to={`/dashboard/subscriptions/${params.id}`}
							className="subscription-button rounded-full px-2  py-1 bg-transparent flex justify-between items-center">
							<a className="subscription-text">Subscriptions</a>
						</Link>
					)}
					{/* <div className="notification-icon-container">
            <Icon as={BsBellFill} w={6} h={6} />
          </div> */}
					<Avatar name={userProfile?.name} src={userProfile?.display_picture} />
				</div>

				<div
					className={`transition-transform duration-500 lg:hidden fixed top-0 bottom-0 right-0 flex-col max-w-full bg-native-blue z-50 h-screen w-full ${
						toggleNav ? "translate-x-0" : "translate-x-full"
					}`}>
					<div onClick={() => setToggleNav(false)} tabIndex={0} className="btn btn-ghost btn-circle mb-4 mt-3 mr-5 self-end">
						<AiOutlineClose className="h-7 w-7 text-white" />
					</div>
					<ul className="p-0">
						{DashboardSideNavLinks?.map((link) => {
							const { name, icon, path, id, accountType } = link;
							const isActive = path == location.pathname || (`${path}/${params.id}` == `${location.pathname}/${params.id}` && true);

							return (
								<React.Fragment key={id}>
									{(userProfile?.account_type && userProfile?.account_type.toLowerCase() === accountType.toLowerCase()) || accountType === "both" ? (
										<NavLink to={path} onClick={() => setToggleNav(false)}>
											<li
												className={`mr-4 flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
													isActive ? "bg-white rounded-tr-full rounded-br-full text-native-blue" : "text-white"
												} `}>
												<div className="flex items-center gap-2">
													<div className="side-nav-icon">{icon}</div>
													<span className="nav-link-name">{name}</span>
												</div>
												{isActive && <BsArrowRightShort size={20} className="rounded-full h-10 p-2 w-10 bg-stone-300" />}
											</li>
										</NavLink>
									) : null}
								</React.Fragment>
							);
						})}
					</ul>
					<div
						onClick={() => logOutFunction()}
						className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}>
						<div className="flex items-center gap-2">
							<BsFillArrowLeftSquareFill />
							<span className="nav-link-name">LogOut</span>
						</div>
						<div className="arrow-container">
							<BsArrowLeftShort className="rounded-full h-10 p-2 w-10 bg-white text-black mr-4" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
