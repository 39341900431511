import React from "react";

function CommunicationTab() {
	return (
		<div className="w-full md:w-5/6 mx-auto">
			<h5>Message all staffs</h5>
			<div className="mt-7">
				<form action="">
					<div className="v-input">
						<textarea
							className="rounded-lg min-h-[10rem] w-full placeholder:opacity-50 focus:border-slate-900/60 outline:border-slate-900/60 bg-slate-100/60 border border-solid"
							placeholder="Write and send a message to all staffs..."></textarea>
					</div>
					<div className="flex items-center justify-end mt-3">
						<button
							type="button"
							className="text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 ease-in-out">
							Send Message
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default CommunicationTab;
