import axios from "axios";
import React, { useEffect, useState } from "react";
import { get_all_attendance } from "../../config/variables";
import { AuthContext } from '../../context/AuthContext';
import Select from "react-select";
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';


function getMonthNameFromDate(dateString) {
	const [day, month, year] = dateString.split("-");
	const dateObject = new Date(`${year}-${month}-${day}`);
	const monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObject);
	return monthName;
}

function generateMonthObjects() {
	const currentYear = new Date().getFullYear();
	const monthObjects = [];

	for (let month = 0; month < 12; month++) {
		const monthIndex = month + 1;
		const monthString = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;

		const dateString = `01-${monthString}-${currentYear}`;

		const monthObject = {
			label: new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(`${currentYear}-${monthString}-01`)),
			value: dateString,
		};

		monthObjects.push(monthObject);
	}

	return monthObjects;
}

function getCurrentMonthDate() {
	const currentDate = new Date();

	const day = currentDate.getDate();
	const month = currentDate.getMonth() + 1;
	const year = currentDate.getFullYear();

	const dayString = day < 10 ? `0${day}` : `${day}`;
	const monthString = month < 10 ? `0${month}` : `${month}`;

	return `${dayString}-${monthString}-${year}`;
}

const EmployeeAttendanceSection = () => {
	const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthDate());
	const [employeeData, setEmployeeData] = useState(null);
	const [monthObject, setMonthObject] = useState(null);
	const { userProfile } = React.useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setMonthObject(generateMonthObjects());
	}, []);

	useEffect(() => {
		setLoading(true);
		(async () => {
			try {
				const response = await axios.post(get_all_attendance, {
					month_of_attendance: selectedMonth,
					account_id: userProfile.company.id
				});
				const data = await response.data;
				setEmployeeData(data);
			} catch (error) {
				// console.log(error);
			}
            setLoading(false);
		})();
	}, [selectedMonth]);

	
	// Tooltip Component
	const Tooltip = React.memo(({ children, content, position = 'top', extraClasses = '' }) => {
		const [showTooltip, setShowTooltip] = useState(false);
	
		const positionClasses = {
		top: 'bottom-full mb-2',
		bottom: 'top-full mt-2',
		left: 'right-full mr-2',
		right: 'left-full ml-2',
		};
	
		return (
		<div className="relative flex items-center">
			<div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
			{children}
			</div>
			{showTooltip && (
			<div className={`absolute z-10 ${positionClasses[position] || positionClasses.top} ${extraClasses} bg-black text-white text-xs rounded p-2 shadow-lg`}>
				{typeof content === 'string' ? <span dangerouslySetInnerHTML={{ __html: content }} /> : content}
			</div>
			)}
		</div>
		);
	});
	
	
	// StatusCell Component
	const StatusCell = ({ status, employee, day,location,device, clock_in_data}) => {
		const tooltipContent = (
		<div style={{minWidth:200}}>
			<p>Name: {employee.name}</p>
			<p>Time Clocked: {day}</p>
			<p>Location: {location}</p>
			<p>Device: {device}</p>
			<p>Status: {status === "bad" ? "Absent" : status === "good" ? "Present" : ""}</p>
		</div>
		);
	
		return (
        <td className={`relative border border-gray-300 p-2 ${status === "bad" ? "bg-red-50" : status === "good" ? "bg-emerald-100" : ""}`}>
            <Tooltip content={tooltipContent} position="bottom" extraClasses="max-w-xs">
                <MainDrawerComponent
                    open={30}
                    card={{ sub: `Clock In data ${employee.name}` }}
                    selected={clock_in_data}
                >   
                    {status === "bad" ? "❌" : status === "good" ? "✔️" : ""}
                </MainDrawerComponent>
            </Tooltip>
        </td>
		);
	};
  
	const renderTable = (data) => (
		<div className="overflow-x-auto">
			<table className="w-full table-auto border-collapse border border-gray-300">
				<thead>
					<tr>
						<th className="border border-gray-300 p-2 text-sm">Employee Name</th>
						{[...Array(31)].map((_, day) => (
							<th key={day} className="border border-gray-300 p-2">
								{day + 1}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.map((employee) => (
						<tr key={employee.id}>
							<td className="border border-gray-300 p-2">{employee?.name}</td>
							{employee.attendance.map((status, day) => (
							    <StatusCell status={status.status} location ={status.location} device={status.device} employee={employee} day={status.timestamp} clock_in_data={status} />
                             ))}
						</tr>
					))}
					
				</tbody>
			</table>
		</div>
	);

	

	return (
		<div className="p-4">
			<label className="font-bold mb-2 block">Showing attendance for:</label>
			<div className="border-gray-300 mb-4 w-auto flex flex-row items-center">
				<Select
					onChange={(e) => setSelectedMonth(e.value)}
					options={monthObject}
					isSearchable={false}
					placeholder={getMonthNameFromDate(selectedMonth)}
				/>
                {loading ? <a className="ml-5">Loading data...</a> : null}
			</div>

			{renderTable(employeeData)}
		</div>
	);
};

export default EmployeeAttendanceSection;
