import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  change_password_end_point,
  login_end_point,
  persist_user_end_point,
  register_end_point,
  request_OTP_end_point,
  verify_OTP_end_point,
} from '../config/variables';
import { useNavigate, useLocation } from 'react-router-dom';
import { TostMessageContext } from './TostMessage';

export const AuthContext = createContext();
// 12345678@Abc
export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const { setTostMessage } = useContext(TostMessageContext);

  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();
  const [resetOtpSent, setResetOtpSent] = useState(false);

  useEffect(() => {
    // // console.log("Path", location.pathname.split("/"));
    if (cookies.userProfile && cookies.userToken && cookies.userToken !== '') {
      setUserProfile(cookies.userProfile);
      setIsLoggedIn(true);
    } else {
      if (location.pathname.split('/')[1] === 'dashboard') {
        setTostMessage({
          messageType: 'error',
          message: 'Session expired Login',
        });
        navigate(`/login`);
      }
    }
  }, []);
  const navigate = useNavigate();
  // useEffect(() => {
  //   getUserProfileFunction();
  // }, []);
  async function getUserProfileFunction() {
    if (cookies.userToken) {
      try {
        console.log('............called');
        const response = await axios.post(
          persist_user_end_point,
          {
            user_token: cookies.userToken,
            account_id: cookies.userProfile?.account_id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status) {
          setUserProfile(response.data.user);
        }
        // console.log("persist_user", response.data.user);
      } catch (error) {
        console.log(error.response);
      }
    }
  }
  async function RegistrationFunction(data, setLoading, reset) {
    try {
      const response = await axios.post(register_end_point, {
        email: data.businessEmail,
        password: data.password,
        business_name: data.businessName,
      });
      if (response.data.statusCode !== 201 || response.data.status === false) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      // setTostMessage({
      //   messageType: "success",
      //   message: response.data.response,
      // });
      sessionStorage.setItem(
        'PMP2022SAVELOGIN',
        JSON.stringify({
          email: data.email,
          password: data.password,
        })
      );

      navigate(`/verify/${JSON.parse(response.config.data).email}`);
    } catch (error) {
      setLoading(false);
    }
  }
  async function LoginFunction(data, setLoading) {
    try {
      const response = await axios.post(login_end_point, {
        ...data,
      });
      if (response.data.statusCode === 402) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
      }
      if (response.data.status === false) {
        if (response.data.response == 'Token expired, please log in.') {
          navigate(`/login`);
        }
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      const date = new Date();
      let expiresDate = date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
      setUserProfile(response.data);
      // console.log('Login Profile:', response.data.user);

      setCookie('userProfile', response.data, {
        path: '/',
        maxAge: expiresDate,
      });

      setCookie('userToken', response.data.token, {
        path: '/',
        maxAge: expiresDate,
      });

      // setTostMessage({
      //   messageType: "success",
      //   message: response.data.response,
      // });
      // navigate  to dashboard
      navigate('/dashboard/home');
      setIsLoggedIn(true);
    } catch (error) {
      console.error(error);
      setLoading(false);

      // return false;
    }
  }
  async function VerifyOTPFunction(data, setLoading) {
    var loginObject = JSON.parse(sessionStorage.getItem('PMP2022SAVELOGIN'));
    // console.log("loginObject", loginObject);
    if (data.email === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Email is required',
      });
      return;
    }
    if (data.otp === '') {
      setTostMessage({
        messageType: 'error',
        message: 'OTP is required',
      });
      return;
    }
    // return // console.log(data);
    try {
      const response = await axios.post(verify_OTP_end_point, {
        ...data,
        // code: data.otp,
        // email: data.email,
        type: 'verify-email',
      });

      if (response.data.statusCode == 401) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      setTostMessage({
        messageType: 'success',
        message: response.data.response,
      });
      LoginFunction(loginObject, setLoading);
      sessionStorage.removeItem('PMP2022SAVELOGIN');
      // navigate  to dashboard
      navigate('/');
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  async function SendOtpForPasswordRestFunction(data, setLoading) {
    if (data.email === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Email is required',
      });
      return;
    }

    // return setResetOtpSent(true);
    try {
      const response = await axios.post(request_OTP_end_point, {
        ...data,
        type: 'password-reset',
      });
      console.log({ response });
      if (response.data.status === false) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      setTostMessage({
        messageType: 'success',
        message: 'Email sent successfully',
      });
      setResetOtpSent(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function SendOtpForEmailVerificationFunction(data) {
    if (data.email === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Email is required',
      });
      return;
    }

    try {
      const response = await axios.post(request_OTP_end_point, {
        ...data,
        type: 'verify-email',
      });

      if (response.data.status === false) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        return;
      }
      // // console.log(response);
      setTostMessage({
        messageType: 'success',
        message: 'Email sent successfully',
      });
      // setResetOtpSent(true);
    } catch (error) {
      console.error(error);
    }
  }
  async function ChangePasswordFunction(data, setLoading) {
    if (data.email === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Email is required',
      });
      return;
    }
    if (data.code === '') {
      setTostMessage({
        messageType: 'error',
        message: 'code is required',
      });
      return;
    }
    if (data.new_password === '') {
      setTostMessage({
        messageType: 'error',
        message: 'New password is required',
      });
      return;
    }
    if (data.confirm_new_password === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Confirm new password is required',
      });
      return;
    }

    try {
      const response = await axios.post(change_password_end_point, {
        ...data,
      });
      if (response.data.status === false) {
        setTostMessage({
          messageType: 'error',
          message: response.data.response,
        });
        setLoading(false);
        return;
      }
      // // console.log(response);
      setTostMessage({
        messageType: 'success',
        message: response.data.response,
      });
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  }

  function logOutFunction() {
    if (!window.confirm('Are you sure you want to log out')) {
      return;
    }
    // localStorage.removeItem("");

    // setUserAccountInformation([]);
    setUserProfile({});
    setIsLoggedIn(false);
    removeCookie('userProfile', { path: '/' });
    removeCookie('userToken', { path: '/' });
    navigate('./');
  }
  return (
    <AuthContext.Provider
      value={{
        logOutFunction,
        isLoggedIn,
        RegistrationFunction,
        LoginFunction,
        VerifyOTPFunction,
        SendOtpForPasswordRestFunction,
        SendOtpForEmailVerificationFunction,
        ChangePasswordFunction,
        resetOtpSent,
        setResetOtpSent,
        userProfile,
        getUserProfileFunction,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
