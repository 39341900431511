import React from 'react';
import { Link } from 'react-router-dom';
import './Subscriptions.scss';
import { ProgressBarChart } from './../../components/ProgressBarComponent/ProgressBarComponent';
import { Icon } from '@chakra-ui/react';
import project_icon from '../../assets/icons/project-icon.svg';
import { BsCheck2Circle } from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import { ProjectsContext } from './../../context/ProjectsContext';
import { AuthContext } from '../../context/AuthContext';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';

function SubscriptionsPage() {
  const [loading, setLoading] = React.useState(true);
  const { projectDetailes, getProjectDetailes } =
    React.useContext(ProjectsContext);

  return (
    <div className="MyStaffs xl:px-10 max-md:px-6 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <MainDrawerComponent
          projectDetailes={projectDetailes?.data?.project_account_details}
          open={19}
          className="max-md:w-[100%] md:w-[100%] items-center"
          card={{ sub: 'Tier 1 Subscription', plan: 'Tier 1', 'price': 150 }}
        >
          <div className="h-auto py-3 px-4 my-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
            <div className="flex flex-column pr-3">
              <a className="font-bold text-[12px]">Tier 1</a>
              <div>
                <h2 className="text-native-blue text-[25px] font-bold mb-0">
                  $150
                </h2>
                <span className="text-[12px]">per month</span>
              </div>
            </div>
            <div className="px-0">
              <a className="font-bold text-[12px]">What you get</a>
              <span className="flex items-center">
                <BsCheck2Circle color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Dedicated project manager</a>
              </span>
              <span className="flex items-center">
                <BsCheck2Circle color="#00b47a" className="mr-1" />
                <a className="text-[13px]">3 Site visits monthly</a>
              </span>
              <span className="flex items-center">
                <BsCheck2Circle color="#00b47a" className="mr-1" />
                <a className="text-[13px]">
                  Realtime Milestone & project reports.
                </a>
              </span>
              <span className="flex items-center">
                <BsCheck2Circle color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Unlimited calls & chats.</a>
              </span>
              <span className="flex items-center">
                <BsCheck2Circle color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Add-on Extra site visits.</a>
              </span>
            </div>
          </div>
        </MainDrawerComponent>
        <MainDrawerComponent
          projectDetailes={projectDetailes?.data?.project_account_details}
          open={19}
          className="max-md:w-[100%] md:w-[100%]"
          card={{ sub: 'Tier 2 Subscription', plan: 'Tier 2', 'price': 250 }}
        >
          <div className="h-auto py-3 my-4 px-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
            <div className="flex flex-column pr-3">
              <a className="font-bold text-[12px]">Tier 2</a>
              <div>
                <h2 className="text-native-blue text-[25px] font-bold mb-0">
                  $250
                </h2>
                <span className="text-[12px]">per month</span>
              </div>
            </div>
            <div className="px-0">
              <a className="font-bold text-[12px]">What you get</a>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Dedicated project manager</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">6 Site visits monthly</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">
                  Realtime Milestone & project reports.
                </a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Unlimited calls & chats.</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Add-on Extra site visits.</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Add-on site livestream option.</a>
              </span>
            </div>
          </div>
        </MainDrawerComponent>
        <MainDrawerComponent
          projectDetailes={projectDetailes?.data?.project_account_details}
          open={19}
          className="max-md:w-[100%] md:w-[100%]"
          card={{ sub: 'Tier 3 Subscription', plan: 'Tier 3', 'price': 500 }}
        >
          <div className="h-auto py-3 my-4 px-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
            <div className="flex flex-column pr-3">
              <a className="font-bold text-[12px]">Tier 3</a>
              <div>
                <h2 className="text-native-blue text-[25px] font-bold mb-0">
                  $500
                </h2>
                <span className="text-[12px]">per month</span>
              </div>
            </div>
            <div className="px-0">
              <a className="font-bold text-[12px]">What you get</a>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Dedicated project manager</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">12 Site visits monthly</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">
                  Realtime Milestone & project reports.
                </a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Unlimited calls & chats.</a>
              </span>
              <span className="flex items-center">
                <AiOutlineCheck color="#00b47a" className="mr-1" />
                <a className="text-[13px]">Site livestream option.</a>
              </span>
            </div>
          </div>
        </MainDrawerComponent>
      </div>
    </div>
  );
}

export default SubscriptionsPage;
