import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { AuthProvider } from './context/AuthContext';
import { ChakraProvider } from '@chakra-ui/react';
import { TostMessageProvider } from './context/TostMessage';
import { VideoCallProvider } from "./context/videoCallContext";
import { PushNotificationProvider } from "./context/PushNotificationContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TostMessageProvider>
        <AuthProvider>
          <ChakraProvider>
            <VideoCallProvider>
              <PushNotificationProvider>
                <App />
              </PushNotificationProvider>
            </VideoCallProvider>
          </ChakraProvider>
        </AuthProvider>
      </TostMessageProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
