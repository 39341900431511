import React, { useEffect, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal/index.jsx";

function OfficeHolidayTab() {
	const dropdownRef = useRef();
	const [isModalAddModalVisible, setIsAddModalVisibility] = useState(false);
	const [isModalUpdateModalVisible, setIsUpdateModalVisibility] = useState(false);
	const [holidayData, setHolidayData] = useState({
		date: null,
		holidayValue: "",
		isDropdownActive: false,
		id: null,
	});
	useEffect(() => {
		function handleOutsideClick(e) {
			if (dropdownRef.current) {
				if (!dropdownRef.current.contains(e.target)) {
					closeAllDropdowns();
				}
			}
		}
		document.addEventListener("click", handleOutsideClick, true);

		return () => document.removeEventListener("click", handleOutsideClick, true);
	}, []);

	const [listOfHolidays, setListOfHolidays] = useState([
		{
			id: 1,
			title: "Ask Me Anything: 10 answers to your questions about coffee",
			isDropdownActive: false,
			date: "2024-12-24",
		},
		{
			id: 2,
			title: "The worst advice we've ever heard about coffee",
			isDropdownActive: false,
			date: "2023-11-03",
		},
	]);

	function handleCurrentItemDropdown(id) {
		setListOfHolidays([
			...listOfHolidays.map((holiday) => {
				if (holiday.id !== id) {
					return {
						...holiday,
						isDropdownActive: false,
					};
				}
				return {
					...holiday,
					isDropdownActive: !holiday.isDropdownActive,
				};
			}),
		]);
	}

	function handleDeleteCurrentHoliday(id) {
		setListOfHolidays([
			...listOfHolidays.filter((holiday) => {
				if (holiday.id !== id) return holiday;
			}),
		]);
	}

	function closeAllDropdowns() {
		setListOfHolidays([
			...listOfHolidays.map((holiday) => {
				return {
					...holiday,
					isDropdownActive: false,
				};
			}),
		]);
	}

	function handleDateUpdate(event) {
		const dateValue = event.target.value;
		setHolidayData((previousData) => {
			return {
				...previousData,
				date: dateValue,
			};
		});
	}
	function handleHolidayInput(event) {
		const textValue = event.target.value;
		setHolidayData((previousData) => {
			return {
				...previousData,
				holidayValue: textValue,
			};
		});
	}

	function handleHolidayAddition() {
		let newData = {
			id: parseInt(listOfHolidays.length + 1),
			title: holidayData.holidayValue,
			isDropdownActive: false,
			date: holidayData.date,
		};
		setListOfHolidays((prevHolidays) => {
			return [...prevHolidays, newData];
		});
		setHolidayData({
			id: null,
			isDropdownActive: false,
			date: null,
			holidayValue: "",
		});
	}

	function handleUpdateModal(id) {
		setIsUpdateModalVisibility(true);

		const currentHoliday = listOfHolidays.find((holiday) => {
			return holiday.id === id;
		});
		setHolidayData({
			id: currentHoliday.id,
			holidayValue: currentHoliday.title,
			date: currentHoliday.date,
			isDropdownActive: currentHoliday.isDropdownActive,
		});
	}

	function handleHolidayUpdate() {
		setListOfHolidays(
			listOfHolidays.map((eachHoliday) => {
				if (eachHoliday.id === holidayData.id) {
					return {
						...eachHoliday,
						holiday: holidayData.holidayValue,
						date: holidayData.date,
					};
				}
				return eachHoliday;
			})
		);
		setIsUpdateModalVisibility(false);
	}

	return (
		<>
			<div className="mb-2">
				<header className="flex items-center justify-end">
					<button
						onClick={() => setIsAddModalVisibility(true)}
						type="button"
						className="text-white/90 bg-[#172337] rounded-md py-2.5 text-sm px-4 w-auto hover:text-white transition-all duration-300 ease-in-out">
						Add Holiday
					</button>
				</header>
			</div>
			<ul className="p-0">
				{listOfHolidays.map((holiday) => (
					<div
						key={holiday.id}
						className={`py-3 px-1 sm:px-sm-2 gap-x-2 flex justify-between items-center ${
							listOfHolidays.at(-1).id === holiday.id ? "border-0" : "border-bottom"
						}`}>
						<div>
							<h3 className="text-sm font-medium m-0">{holiday.title}</h3>
							<span className="opacity-40">Date: {holiday?.date}</span>
						</div>
						<div className="relative">
							<button
								type="button"
								onClick={() => handleCurrentItemDropdown(holiday.id)}
								className="p-2 rounded-full hover:bg-primary hover:text-white duration-300 transition-all ease-in-out">
								<HiDotsVertical className="w-4 h-4" />
							</button>
							{holiday.isDropdownActive === true ? (
								<div
									ref={dropdownRef}
									className="absolute right-0 top-full transition-all ease-in-out duration-300 w-[250px] z-2 bg-white shadow-lg border border-solid border-slate-200/20 rounded-lg p-1 mt-2 pointer-events-auto">
									<div className="p-0 m-0 flex flex-col my-1">
										<li>
											<button
												onClick={() => handleUpdateModal(holiday.id)}
												type="button"
												className="py-2 px-2 w-full flex items-center gap-x-3 hover:bg-slate-100 transition duration-300 ease-in-out rounded-md">
												<span className="v-icon">
													<FaPencilAlt />
												</span>
												<span className="text-sm">Update</span>
											</button>
										</li>
										<li>
											<button
												onClick={() => handleDeleteCurrentHoliday(holiday.id)}
												type="button"
												className="py-2 px-2 w-full flex items-center gap-x-3 hover:bg-slate-100 transition duration-300 ease-in-out rounded-md">
												<span className="v-icon">
													<MdDelete className="w-5 h-5" />
												</span>
												<span className="text-sm">Delete</span>
											</button>
										</li>
									</div>
								</div>
							) : null}
						</div>
					</div>
				))}
			</ul>

			{/* update modal @::start */}
			{isModalUpdateModalVisible === true ? (
				<ModalComponent modalTitle="Update Holiday" isActiveModal={isModalUpdateModalVisible} closeModal={() => setIsUpdateModalVisibility(false)}>
					<div className="my-4 flex flex-col gap-y-4">
						<div>
							<label htmlFor="">Holiday </label>
							<input
								type="text"
								value={holidayData.holidayValue}
								onChange={handleHolidayInput}
								className="w-full rounded-md border border-slate-400 placeholder:opacity-50"
								placeholder="Enter holiday description"
							/>
						</div>
						<div>
							<label htmlFor="">Add date</label>
							<input type="date" value={holidayData.date} onChange={handleDateUpdate} className="w-full rounded-md border border-slate-400" />
						</div>
						<div className="mt-4 flex items-center justify-end">
							<button
								type="button"
								onClick={handleHolidayUpdate}
								className="text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out">
								Update Holiday
							</button>
						</div>
					</div>
				</ModalComponent>
			) : null}
			{/* update modal @::end */}

			{/* add modal @::start */}
			{isModalAddModalVisible === true ? (
				<ModalComponent modalTitle="Add Holiday" isActiveModal={isModalAddModalVisible} closeModal={() => setIsAddModalVisibility(false)}>
					<div className="my-4 flex flex-col gap-y-4">
						<div>
							<label htmlFor="">Add holiday </label>
							<input
								type="text"
								value={holidayData.holidayValue}
								onChange={handleHolidayInput}
								className="w-full rounded-md border border-slate-400 placeholder:opacity-50"
								placeholder="Enter holiday description"
							/>
						</div>
						<div>
							<label htmlFor="">Add date</label>
							<input type="date" value={holidayData.date} onChange={handleDateUpdate} className="w-full rounded-md border border-slate-400" />
						</div>
						<div className="mt-4 flex items-center justify-end">
							<button
								type="button"
								onClick={handleHolidayAddition}
								className="text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out">
								Add Holiday
							</button>
						</div>
					</div>
				</ModalComponent>
			) : null}
			{/* add modal @::end */}
		</>
	);
}

export default OfficeHolidayTab;
