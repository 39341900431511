import React from "react";

function GeneralTab() {
	return (
		<div className="flex flex-col gap-y-6 overflow-y-auto max-h-[500px] pr-4">
			{/* <== each section @::start  ==> */}
			<div className="grid grid-cols-1 sm:grid-cols-5 gap-y-2 pb-4 border-bottom">
				<div className="sm:col-span-2">
					<div className="flex flex-col">
						<h6 className="m-0">Fullname</h6>
						<span className="opacity-60">Update your name</span>
					</div>
				</div>
				<div className="col-span-3 flex flex-col">
					<input type="text" value="Okeke JohnPaul" className="border-slate-400/50 border-solid w-full rounded-md border-[1.5px] min-h-[3rem]" />
				</div>
			</div>
			{/* <== each section @::end  ==> */}

			{/* <== each section @::start  ==> */}
			<div className="grid grid-cols-1 sm:grid-cols-5 gap-y-2 pb-4 border-bottom">
				<div className="sm:col-span-2">
					<div className="flex flex-col">
						<h6 className="m-0">Tagline</h6>
						<span className="opacity-60">A quick snapshot of your dashboard</span>
					</div>
				</div>
				<div className="col-span-3 flex flex-col">
					<textarea
						type="text"
						value=""
						className="border-slate-400/50 border-solid w-full rounded-md border-[1.5px] min-h-[5rem] resize-none"></textarea>
				</div>
			</div>
			{/* <== each section @::end  ==> */}
			{/* <== each section @::start  ==> */}
			<div className="grid grid-cols-1 sm:grid-cols-5 gap-y-2 pb-4 border-bottom">
				<div className="sm:col-span-2">
					<div className="flex flex-col">
						<h6 className="m-0">Tagline</h6>
						<span className="opacity-60">A quick snapshot of your dashboard</span>
					</div>
				</div>
				<div className="col-span-3 flex flex-col">
					<textarea
						type="text"
						value=""
						className="border-slate-400/50 border-solid w-full rounded-md border-[1.5px] min-h-[5rem] resize-none"></textarea>
				</div>
			</div>
			{/* <== each section @::end  ==> */}

			{/* <== each section @::start  ==> */}
			<div className="grid grid-cols-1 sm:grid-cols-5 gap-y-2">
				<div className="flex flex-col sm:col-span-2">
					<h6 className="m-0">Advance settings</h6>
					<span className="opacity-60">Reset to default</span>
				</div>
				<div className="col-span-3 flex flex-col gap-y-4">
					<div className="flex items-center gap-x-4">
						<input
							type="checkbox"
							className="w-4 h-4 rounded-sm border-slate-300/80 border aspect-square cursor-pointer checked:shadow-none checked:ring-off-0"
						/>
						<div>
							<h6 className="mb-0">Allow administrators to invite a new memebers</h6>
							<span className="text-[.9rem] opacity-70">Any administrator can manage application members and send invites</span>
						</div>
					</div>
					<div className="flex items-center gap-x-4">
						<input
							type="checkbox"
							className="w-4 h-4 rounded-sm border-slate-300/80 border aspect-square cursor-pointer checked:shadow-none checked:ring-off-0"
						/>
						<div>
							<h6 className="mb-0">Allow administrators to to change names</h6>
							<span className="text-[.9rem] opacity-70">Any administrator can change project names and URLS</span>
						</div>
					</div>
				</div>
			</div>
			{/* <== each section @::end  ==> */}

			<div className="flex items-center justify-start mt-6">
				<button
					type="button"
					className="text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out">
					Save settings
				</button>
			</div>
		</div>
	);
}

export default GeneralTab;
