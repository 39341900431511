import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  loginValidationSchema,
  emailForPasswordResetValidationSchema,
  passwordResetValidationSchema,
  registerValidationSchema,
  otpValidationSchema,
} from '../../config/yup';
import './FormsComponent.scss';
import { FormInputField } from './CostumeFormElement';
import { AuthContext } from '../../context/AuthContext';

import { useNavigate, useParams } from 'react-router-dom';
// import { toast } from "react-toastify";
import { TostMessageContext } from './../../context/TostMessage';
import { Box, FormLabel, Input, Icon, useDisclosure } from '@chakra-ui/react';
// import { SavedBankAccountCard } from "./../AccountSetUpCard/DrawerComponent";
import naira_icon from '../../assets/icons/naira.svg';
import axios from 'axios';
import { fetch_bank_list_endpoint } from '../../config/variables';
import { useCookies } from 'react-cookie';

export function LoginFormsComponent() {
  const { LoginFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };
  const validationSchema = loginValidationSchema;
  const onSubmit = (values) => {
    setLoading(true);
    // // console.log(values);
    LoginFunction(values, setLoading);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="FormsComponent">
            <form onSubmit={handleSubmit}>
              <FormInputField
                label="Email"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email && touched.email ? 'error' : null}
                checkErrors={errors.email}
                checkTouched={touched.email}
              />
              <FormInputField
                label="Password"
                type="password"
                name="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.password && touched.password ? 'error' : null}
                checkErrors={errors.password}
                checkTouched={touched.password}
              />
              <button
                type="submit"
                className={dirty && isValid ? '' : 'disabled-btn'}
                // disabled={!(dirty && isValid)}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Login'}
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}
export function RegisterFormsComponent() {
  const { RegistrationFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    passwordConfirmation: '',
    account_type: '',
  };
  const validationSchema = registerValidationSchema;
  const onSubmit = (values) => {
    setLoading(true);
    // // console.log(values);
    RegistrationFunction(values, setLoading);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="FormsComponent">
            <form onSubmit={handleSubmit}>
              <div className="d-md-flex d-block justify-content-between">
                <FormInputField
                  label="Fist Name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.first_name && touched.first_name ? 'error' : null
                  }
                  checkErrors={errors.first_name}
                  checkTouched={touched.first_name}
                />
                <FormInputField
                  label="Last Name"
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.last_name && touched.last_name ? 'error' : null
                  }
                  checkErrors={errors.last_name}
                  checkTouched={touched.last_name}
                />
              </div>
              <FormInputField
                label="Email"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email && touched.email ? 'error' : null}
                checkErrors={errors.email}
                checkTouched={touched.email}
              />
              <FormInputField
                label="Password"
                type="password"
                name="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.password && touched.password ? 'error' : null}
                checkErrors={errors.password}
                checkTouched={touched.password}
              />
              <FormInputField
                label="Confirmation Password"
                type="password"
                name="passwordConfirmation"
                id="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.passwordConfirmation && touched.passwordConfirmation
                    ? 'error'
                    : null
                }
                checkErrors={errors.passwordConfirmation}
                checkTouched={touched.passwordConfirmation}
              />

              <div className={`account-type-radio mb-5`}>
                Join as a client or contractor
                <label className="d-flex gap-2 mb-3">
                  <input
                    type="radio"
                    name="account_type"
                    id="client"
                    onChange={handleChange}
                    value={'client'}
                    className="mb-auto mt-2 ms-2"
                  />{' '}
                  <div>
                    <b>Client</b> <br />
                    I’m a client, hiring for a project
                  </div>
                </label>
                <label className="d-flex gap-2">
                  <input
                    type="radio"
                    name="account_type"
                    id="contractor"
                    onChange={handleChange}
                    value={'contractor'}
                    className="mb-auto mt-2 ms-2"
                  />{' '}
                  <div>
                    <b>Contractor</b> <br />
                    I’m a contractor, looking for work
                  </div>
                </label>
                {errors.account_type && (
                  <span className="error fw-bolder ms-3 mt-2 d-block">
                    {errors.account_type}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className={dirty && isValid ? '' : 'disabled-btn'}
                // disabled={!(dirty && isValid)}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Sign up'}
              </button>

              <div className="text-center mt-4">
                By clicking the “Create your account” button, <br /> you agree
                to <a href="/"> Urbex Africa’s terms of acceptable use</a>.
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}

export function OtpVerificationFormComponent() {
  const { SendOtpForEmailVerificationFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const { setTostMessage } = useContext(TostMessageContext);
  const { email } = useParams();
  const navigate = useNavigate();
  const { VerifyOTPFunction } = useContext(AuthContext);
  const initialValues = {
    code: '',
  };

  useEffect(() => {
    if (email === '') {
      setTostMessage({
        messageType: 'error',
        message: 'Email not found login again',
      });
      navigate('/login');
    }
  }, []);
  const validationSchema = otpValidationSchema;
  const onSubmit = (values) => {
    // // console.log(values);
    setLoading(true);
    VerifyOTPFunction({ email: email, ...values }, setLoading);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="FormsComponent">
            <form onSubmit={handleSubmit}>
              <FormInputField
                label="OTP PIN"
                type="password"
                name="code"
                id="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.code && touched.code ? 'error' : null}
                checkErrors={errors.code}
                checkTouched={touched.code}
              />
              <button
                type="submit"
                className={dirty && isValid ? '' : 'disabled-btn'}
                disabled={!(dirty && isValid) || loading}
              >
                {loading ? 'Loading...' : 'Verify'}
              </button>
              <div className="text-center mt-3">
                Do not get the Email?{' '}
                <span
                  className="text-primary fw-bolder"
                  onClick={() => SendOtpForEmailVerificationFunction({ email })}
                >
                  {' '}
                  Resend mail
                </span>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}
export function EmailForPasswordResetFormComponent({ setKeepEmail }) {
  const { SendOtpForPasswordRestFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: '',
  };

  const validationSchema = emailForPasswordResetValidationSchema;
  const onSubmit = (values) => {
    // // console.log(values);
    setLoading(true);
    setKeepEmail(values.email);
    SendOtpForPasswordRestFunction(values, setLoading);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="FormsComponent">
            <form onSubmit={handleSubmit}>
              <FormInputField
                label="Email Address"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email && touched.email ? 'error' : null}
                checkErrors={errors.email}
                checkTouched={touched.email}
              />

              <button
                type="submit"
                className={dirty && isValid ? '' : 'disabled-btn'}
                disabled={!(dirty && isValid) || loading}
              >
                {loading ? 'Loading...' : 'Verify'}
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}
export function PasswordResetFormComponent({ email }) {
  const { ChangePasswordFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    code: '',
    new_password: '',
    confirm_new_password: '',
  };

  const validationSchema = passwordResetValidationSchema;
  const onSubmit = (values) => {
    // // console.log({ ...values, email });
    setLoading(false);
    ChangePasswordFunction({ ...values, email });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="FormsComponent">
            <form onSubmit={handleSubmit}>
              <FormInputField
                label="OTP"
                type="number"
                name="code"
                id="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.code && touched.code ? 'error' : null}
                checkErrors={errors.code}
                checkTouched={touched.code}
              />
              <FormInputField
                label="New Password"
                type="password"
                name="new_password"
                id="new_password"
                value={values.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.new_password && touched.new_password ? 'error' : null
                }
                checkErrors={errors.new_password}
                checkTouched={touched.new_password}
              />
              <FormInputField
                label="Confirm New Password"
                type="password"
                name="confirm_new_password"
                id="confirm_new_password"
                value={values.confirm_new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.confirm_new_password && touched.confirm_new_password
                    ? 'error'
                    : null
                }
                checkErrors={errors.confirm_new_password}
                checkTouched={touched.confirm_new_password}
              />

              <button
                type="submit"
                className={dirty && isValid ? '' : 'disabled-btn'}
                disabled={!(dirty && isValid) || loading}
              >
                Verify
                {loading ? 'Loading...' : 'Verify'}
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}

export function WithdrawTabFormComponent({ requestWithdrawalFunction }) {
  const firstField = React.useRef();
  const { id } = useParams();
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();
  console.log(userProfile);
  const [banks, setBanks] = useState({
    loading: true,
    data: [],
  });

  function getBanksFunction() {
    let data = JSON.stringify({
      user: { account_id: userProfile?.account_id },
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: fetch_bank_list_endpoint,
      headers: {
        access: cookies.userToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.banks);
        setBanks({ loading: false, data: response.data.banks.banks });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getBanksFunction();
  }, []);

  return (
    <div className="WithdrawTabFormComponent mt-10">
      <form
        action=""
        onSubmit={(e) => {
          requestWithdrawalFunction(e);
        }}
      >
        <div className="d-flex">
          <span className="my-auto px-4">
            {/* <Icon /> */}
            <img src={naira_icon} alt="" />
          </span>{' '}
          <input
            className="withdraw-input"
            placeholder="Enter withdrawal amount"
          />
        </div>
        <Box className="my-3">
          <FormLabel style={{ fontSize: 13 }} htmlFor="id_file">
            Account Number
          </FormLabel>
          <input
            ref={firstField}
            className="withdraw-input"
            placeholder="Enter account number"
            // onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
        <Box className="my-3">
          <FormLabel style={{ fontSize: 13 }} htmlFor="id_file">
            Account Name
          </FormLabel>
          <input
            ref={firstField}
            placeholder="Enter account name"
            className="withdraw-input"
            // onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
        <Box className="my-3">
          <FormLabel style={{ fontSize: 13 }} htmlFor="id_file">
            Bank
          </FormLabel>
          <select className="withdraw-input">
            {banks.loading ? (
              <option>Loading...</option>
            ) : (
              <>
                <option value="">Select Bank...</option>
                {banks.data.map((bank) => (
                  <option value={bank.nip_bank_code} key={bank.id}>
                    {bank.bank_name}
                  </option>
                ))}
              </>
            )}
          </select>
        </Box>
        <Box className="my-3">
          <FormLabel style={{ fontSize: 13 }} htmlFor="narration n">
            Narration
          </FormLabel>
          <textarea
            className="withdraw-input"
            name="narration"
            id="narration"
            placeholder="reason for withdrawal"
            cols="5"
            rows="5"
          ></textarea>
          {/* <Input
            ref={firstField}
            id="occupation"
            name="occupation"
            // onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" * /}
          /> */}
        </Box>

        {/* <div className="d-flex justify-content-between my-4">
          Bank Account
          <button className="add" onClick={() => setAddCard(true)}>
            <Icon />
          </button>
        </div>
        <SavedBankAccountCard />
        <SavedBankAccountCard />
        <SavedBankAccountCard /> */}

        <button
          type="submit"
          className="text-center hover:bg-[primary] px-4 text-white bg-secondary py-3 rounded-full"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
// export function AddBankCardFormComponent({ setAddCard }) {
//   const firstField = React.useRef();
//   return (
//     <div className="WithdrawTabFormComponent">
//       <form action="">
//         <div className="my-4">
//           <Input placeholder="Name of account " />
//         </div>
//         <div className="my-4">
//           <Input placeholder="Bank Name" />
//         </div>
//         <div className="my-4">
//           <Input placeholder="Account number" />
//         </div>

//         <button className="submit mt-5">Add</button>
//       </form>
//     </div>
//   );
// }
