export function blobToURL(blob) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = function () {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
}

export async function fileToBlob(file, handleUpdate) {
	const { content, size } = file;
	let chunks = [];
	let i = 0;
	const totalCount = Math.round(size / 250000);

	for await (const chunk of content) {
		if (handleUpdate) {
			handleUpdate(i, totalCount);
		}
		chunks.push(chunk);
		i++;
	}
	return new Blob(chunks);
}

export const primary = "#2b6284";
export const primary2 = "#ecf4f9";
export const primary3 = "#9fc7e0";
export const primary35 = "#97bace";
export const primary4 = "hsl(204,38%,55%)";
export const primary45 = "hsl(218,49%,66%)";
export const primary46 = "#6778cb";
export const primary15 = "rgb(241 249 255)";
export const primary5 = "#3881ad";
export const primary6 = "#132b3a";
export const primary16 = "hsl(208 100% 96% / 1)";
export const errorColor = "#ef6565";
export const lightErrorColor = "#ef9c9c";
export const goodColor = "#53c171";
export const cleanBorder = "1px solid rgb(208, 227, 239)";
export const lightBorder = "hsl(203 51% 80% / 1)";
