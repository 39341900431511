import React from "react";

function Activities() {
	return (
		<div className="p-2 mt-4">
			<div className="p-4 bg-white rounded-lg max-w-6xl mx-auto">
				<div className="flex flex-col gap-y-7">
					{/* each activity container @::start */}
					<div>
						<h5>10 June</h5>
						<div className="relative border-l-[2px] flex flex-col gap-y-3">
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
						</div>
					</div>
					{/* each activity container @::end */}
					{/* each activity container @::start */}
					<div>
						<h5>24 April</h5>
						<div className="relative border-l-[2px] flex flex-col gap-y-3">
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
							<div className="relative flex items-center justify-between before:content-[''] before:bg-black before:absolute before:top-1/2 before:-left-[3px] before:-translate-y-1/2 before:rounded-full before:h-[5px] before:w-[5px] before:aspect-square indent-5">
								<span>Ask Me Anything: 10 answers to your questions about coffee</span>
								<span className="opacity-80">07:00pm</span>
							</div>
						</div>
					</div>
					{/* each activity container @::end */}
					<div></div>
				</div>
			</div>
		</div>
	);
}

export default Activities;
