import React, { useEffect, useState } from 'react';
import {
  create_department,
  create_department_level,
  get_permissions,
  staff_permissions,
  update_staff,
} from '../../config/variables';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaChevronDown, FaUserEdit } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';

import { AiOutlineClose } from 'react-icons/ai';

export default function Procurement() {
  const [procurementItem, setProcurementItem] = useState(null);
  const [procurementList, setProcurementList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userProfile } = React.useContext(AuthContext);

  const [cookies] = useCookies();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const getDepartments = async () => {
    try {
      const response = await axios.get(staff_permissions, {
        headers: {
          'Content-Type': 'multipart/form-data',
          access: cookies.userToken,
        },
      });

      const { data } = await response.data;
      setProcurementList(data);
      reset();
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(create_department, {
        title: data.title,
        details: data.description,
        account_id: userProfile.user.account_id,
      });
      const resData = await response.data;
      console.log(resData);
      getDepartments();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const onError = (data) => {
    console.log({ data });
  };

  return (
    <div className="max-lg:px-5 px-10">
      <>
        <div className="flex items-center mt-7 justify-between">
          <h4 className="mt-2">All Requests</h4>
        </div>
        <div className="mt-5">
          <>
            {loading ? (
              <>
                {Array.from({ length: 10 })?.map((item, index) => (
                  <div
                    className="bg-gray-200 animate-pulse my-4 rounded-2xl border py-4"
                    key={index}
                  ></div>
                ))}
              </>
            ) : (
              <>
                {procurementList?.map((item, index) => (
                  <MainDrawerComponent
                    projectDetailes={item}
                    open={21}
                    className="max-md:w-[100%] md:w-[100%] items-center"
                    card={{
                      sub: 'Procurement',
                    }}
                    key={index}
                  >
                    <div
                      className="bg-white flex justify-between items-center px-4 my-2 rounded-xl border py-3"
                      onClick={() => setProcurementItem(item)}
                    >
                      <h6>{item.title}</h6>
                      <div className="">
                        <BsThreeDotsVertical />
                      </div>
                    </div>
                  </MainDrawerComponent>
                ))}
              </>
            )}
          </>
        </div>
      </>
    </div>
  );
}

export function ProcurementDetail({ departmentItem, getDepartments }) {
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [createLevel, setCreateLevel] = useState(false);
  const [editStaffLoading, setEditStaffLoading] = useState(false);
  const [departmentDetail, setDepartmentDetail] = useState(null);
  const [permissionList, setPermissionList] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedPermissionDisplay, setSelectedPermissionDisplay] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [permissionErrState, setPermissionErrState] = useState(false);

  const getPermissions = async () => {
    const response = await axios.get(get_permissions, {
      headers: {
        'Content-Type': 'multipart/form-data',
        access: cookies.userToken,
      },
    });

    const { data } = await response.data;
    setFetchLoading(false);
    setPermissionList(data);
  };

  useEffect(() => {
    setDepartmentDetail(departmentItem);
    getPermissions();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  function joinArrayElements(arr) {
    return arr.join(',');
  }
  const onCreateLevel = async (data) => {
    const permission = joinArrayElements(selectedPermissions);
    if (!permission) {
      setPermissionErrState(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(create_department_level, {
        name: data.name,
        permissions: permission,
        department_id: departmentDetail?.id,
        account_id: userProfile.user.account_id,
      });
      const resData = await response.data;
      console.log(resData);
      getDepartments();
      setCreateLevel(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function removeObjectById(id, arrayOfObjects) {
    const selectedArray = selectedPermissions.filter((obj) => obj !== id);
    const newArray = arrayOfObjects.filter((obj) => obj.id !== id);
    setSelectedPermissions(selectedArray);
    setSelectedPermissionDisplay(newArray);
    return;
  }

  return (
    <div className="">
      {fetchLoading ? (
        <div className="w-full">
          <div
            className="h-20 w-20 rounded-full bg-gray-200 animate-pulse mt-5"
            alt=""
          />
          <div className="mt-3">
            <p className="m-0 bg-gray-200 h-10 mb-3 w-2/3 font-medium"></p>
            <p className="m-0 bg-gray-200 h-10 mb-3 w-8/12 font-medium"></p>
            <hr className="mt-7 mb-4 border-gray-400" />

            <button className="px-10 py-3 h-14 w-36 rounded-xl bg-gray-200 text-white"></button>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <div className="flex items-center max-w-lg justify-between">
            <p className="m-0 text-lg font-bold">{departmentDetail?.title}</p>
            <button
              onClick={() => setCreateLevel(true)}
              className="px-6 flex items-center gap-2 py-2 rounded-lg text-black bg-white border"
            >
              <FaUserEdit /> Create Level
            </button>
          </div>

          <div className="mt-16">
            {
              <div className={`max-w-4xl`}>
                <p className="m-0 text-lg text-gray-600 font-medium">
                  {departmentDetail?.details}
                </p>
                <hr className="border-gray-300 border" />
                <h5 className="mt-4">Department Levels</h5>
                <div className="flex flex-wrap gap-4">
                  {departmentDetail?.department_level.map((item, index) => (
                    <div
                      className="px-5 flex items-center gapx-2 py-2 border rounded-lg text-black text-sm bg-white"
                      key={index}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      )}

      {createLevel && (
        <div className="fixed inset-0  flex items-center justify-center z-50 bg-black bg-opacity-75">
          <form
            onSubmit={handleSubmit(onCreateLevel)}
            className="rounded-xl pb-7 flex-col max-w-2xl w-full px-4 lg:px-10 flex mx-4 pt-4 bg-white shadow-lg"
          >
            <div className="flex items-center mb-4 justify-between">
              <p className="text-lg m-0 font-bold">Create Level</p>
              <button
                type="button"
                className="text-black self-end text-xl rounded-xl py-2"
                onClick={() => {
                  setCreateLevel(false);
                }}
              >
                <AiOutlineClose />
              </button>
            </div>

            <input
              className={`rounded-lg border mt-4 ${
                errors.name && 'border border-red-400'
              }`}
              type="text"
              placeholder="Level title"
              {...register('name', { required: true })}
            />
            <button
              type="button"
              onClick={() => setShow(true)}
              className="flex justify-between p-2 mt-4 rounded-lg border"
            >
              <p className="m-0">Select Permission</p> <FaChevronDown />
            </button>

            {permissionErrState && (
              <p className="text-red-400 text-xs mt-1">
                Please select a permission
              </p>
            )}
            {permissionList?.length !== 0 && show && (
              <div className="px-2 bg-white border transition-all rounded-lg mt-1">
                {permissionList.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setSelectedPermissions((prevSelectedPermission) => {
                        if (!prevSelectedPermission.includes(item.id)) {
                          return [...prevSelectedPermission, item.id];
                        }
                        return prevSelectedPermission;
                      });
                      setSelectedPermissionDisplay((prevState) => {
                        const isItemAlreadySelected = prevState.find(
                          (selectedItem) => selectedItem.id === item.id
                        );
                        if (!isItemAlreadySelected) {
                          return [...prevState, item];
                        }
                        return prevState;
                      });
                      setShow(false);
                      setPermissionErrState(false);
                    }}
                    className="my-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3"
                  >
                    {/* <div>{item.name}</div> */}
                    <div>{item.description}</div>
                  </div>
                ))}
              </div>
            )}

            <div className="px-2 bg-white mt-4 flex gap-x-3 flex-wrap transition-all rounded-lg">
              {selectedPermissionDisplay?.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() =>
                    removeObjectById(item.id, selectedPermissionDisplay)
                  }
                  className="mt-2 bg-gray-50 border rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3"
                >
                  <div>{item.name}</div>
                  <IoClose />
                </div>
              ))}
            </div>

            <button
              type="submit"
              className="text-white bg-native-blue mt-4 text-xs px-10 border active:scale-95 transition-all self-end rounded-xl py-2"
            >
              {loading ? 'Save...' : 'Save'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
