import React, {
  useContext,
  // ,
  // useEffect,
  //   useState
} from "react";
import { BsX } from "react-icons/bs";
import "./TostMessageComponent.scss";
import { TostMessageContext } from "./../../context/TostMessage";

import errorImg from "../../assets/icons/Vector.svg";
import successImg from "../../assets/icons/Vector-2.svg";

function TostMessageComponent() {
  const { tostMessage, setTostMessage } = useContext(TostMessageContext);

  // useEffect(
  //   () =>
  //     setTostMessage({ messageType: "error", message: "test error message" }),
  //   [setTostMessage]
  // );
  // let icon;

  // if (tostMessage.messageType === "error") {
  //   icon = errorImg;
  // } else if (tostMessage.messageType === "success") {
  //   icon = successImg;
  // } else if (tostMessage.messageType === "warning") {
  //   icon = warningImg;
  // }

  return (
    <div
      className={`TostMessageComponent ${tostMessage.messageType}`}
      style={tostMessage.messageType !== "" ? null : { display: "none" }}
    >
      <div className="icon-container">
        {tostMessage.messageType === "error" ? (
          <img src={errorImg} alt="error" />
        ) : (
          <img src={successImg} alt="success" />
        )}
      </div>
      <div className="text-container">
        <h3>{tostMessage.messageType}</h3>
        <p className="w-100">{tostMessage.message}</p>
      </div>
      <div className="close">
        <BsX onClick={() => setTostMessage({ messageType: "", message: "" })} />
      </div>
    </div>
    // <>
    //   {/* {tostMessage.messageType !== "" ? null : ( */}
    //   <Alert
    //   //  status={tostMessage.messageType}
    //   >
    //     <AlertIcon />
    //     <Box>
    //       <AlertTitle>Success!</AlertTitle>
    //       <AlertDescription>
    //         Your application has been received. We will review your application
    //         and respond within the next 48 hours.
    //       </AlertDescription>
    //     </Box>
    //     <CloseButton
    //       alignSelf="flex-start"
    //       position="relative"
    //       right={-1}
    //       top={-1}
    //       onClick={setTostMessage({ messageType: "", message: "" })}
    //     />
    //   </Alert>
    //   {/* )} */}
    // </>
  );
}

export default TostMessageComponent;
