import React from "react";
import { useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IoFilterSharp } from "react-icons/io5";
import ModalComponent from "../../components/Modal";

function Assests() {
	const [isActiveFilterDropdown, setIsActiveFilterDropdown] = useState(false);
	const [isItemModalActive, setItemModal] = useState(false);
	const fileInputRef = useRef();
	const dropdownRef = useRef();
	const [currentObjectToBeViewedInModal, setCurrentObjectToBeViewInModal] = useState({});

	useEffect(() => {
		function handleOutsideClick(e) {
			if (dropdownRef.current) {
				if (!dropdownRef.current.contains(e.target)) {
					setIsActiveFilterDropdown(false);
				}
			}
		}
		document.addEventListener("click", handleOutsideClick, true);

		return () => document.removeEventListener("click", handleOutsideClick, true);
	}, []);

	function handleOpenDeviceFilesDialog() {
		if (fileInputRef && fileInputRef.current !== null) {
			fileInputRef.current.click();
		}
	}

	function handleFilterDropdown() {
		setIsActiveFilterDropdown((currentState) => !currentState);
	}

	const [assets, setAssests] = useState([
		{
			id: 1,
			detail: "Object detail 1",
			tag: ["assets", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
		{
			id: 2,
			detail: "Object detail 2",
			tag: ["assets", "maintenance", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
		{
			id: 3,
			detail: "Object detail 3",
			tag: ["assets", "maintenance", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
		{
			id: 4,
			detail: "Object detail 4",
			tag: ["assets", "maintenance", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
		{
			id: 5,
			detail: "Object detail 5",
			tag: ["assets", "maintenance", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
		{
			id: 6,
			detail: "Object detail 6",
			tag: ["assets", "maintenance", "category"],
			checked: false,
			image_link: "https://via.placeholder.com/150",
		},
	]);

	function handleCurrentItemDropdown(id) {
		setCurrentObjectToBeViewInModal({
			...assets.find((currentItem) => {
				return currentItem.id === id;
			}),
		});
		setItemModal(true);
	}

	function setIsChecked(id) {
		setAssests([
			...assets.map((currentItem) => {
				if (currentItem.id !== id) {
					return currentItem;
				}
				return {
					...currentItem,
					checked: !currentItem.checked,
				};
			}),
		]);
	}

	return (
		<div className="p-4">
			<header className="flex items-center justify-between">
				<div className="">
					<button
						type="button"
						onClick={handleOpenDeviceFilesDialog}
						className="text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out">
						Add new assets
					</button>
					<input type="file" hidden ref={fileInputRef} />
				</div>
				<div className="relative flex items-center gap-x-2">
					<span className="hidden sm:inline-block">Filter:</span>
					<button
						type="button"
						onClick={handleFilterDropdown}
						className={`hover:bg-gray-200 rounded-full p-2 w-auto transition-all duration-300 text-sm ease-in-out ${
							isActiveFilterDropdown ? "bg-gray-200" : "bg-transparent"
						}`}>
						<IoFilterSharp className="select-none pointer-events-none w-5 h-5" />
					</button>
					{isActiveFilterDropdown ? (
						<div
							ref={dropdownRef}
							className="absolute right-0 top-full transition-all ease-in-out duration-300 w-[280px] z-2 bg-white shadow-md rounded-lg p-3 mt-2">
							<ul className="p-0 m-0 flex flex-col gap-y-2 my-1">
								<label htmlFor="" className="flex items-center gap-x-3">
									<input
										type="checkbox"
										className="border-[1.5px] border-slate-300 outline-none checked:bg-[#172337] focus:shadow-none focus:ring-[#172337] h-5 w-5 rounded-sm transition-[x]"
									/>
									<span className="text-black/80">Assets</span>
								</label>
								<label htmlFor="" className="flex items-center gap-x-3">
									<input
										type="checkbox"
										className="border-[1.5px] border-slate-300 outline-none checked:bg-[#172337] focus:shadow-none focus:ring-[#172337] h-5 w-5 rounded-sm transition-[x]"
									/>
									<span className="text-black/80">Category</span>
								</label>
								<label htmlFor="" className="flex items-center gap-x-3">
									<input
										type="checkbox"
										className="border-[1.5px] border-slate-300 outline-none checked:bg-[#172337] focus:shadow-none focus:ring-[#172337] h-5 w-5 rounded-sm transition-[x]"
									/>
									<span className="text-black/80">Maintenance</span>
								</label>
							</ul>
						</div>
					) : null}
				</div>
			</header>
			{assets && (
				<div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
					{assets.map((eachAssest) => {
						return (
							<React.Fragment key={eachAssest.id}>
								<div className="flex flex-col p-2 shadow-sm border border-slate-50/50 gap-y-4 rounded-md">
									<div className="w-full flex items-center justify-center max-h-40 overflow-hidden rounded-md ">
										<img src={eachAssest.image_link} alt={eachAssest.image_link} className=" max-w-full w-full h-full object-cover object-center" />
									</div>
									<div class="flex items-center justify-between">
										<span>Something</span>
										<button
											type="button"
											onClick={() => handleCurrentItemDropdown(eachAssest.id)}
											className="p-2 rounded-full hover:bg-primary hover:text-white duration-300 transition-all ease-in-out">
											<HiDotsVertical className="w-4 h-4" />
										</button>
									</div>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			)}

			{isItemModalActive === true ? (
				<ModalComponent modalTitle="Item Modal Title" isActiveModal={isItemModalActive} closeModal={() => setItemModal(false)}>
					<div className="py-4">
						<span>
							{currentObjectToBeViewedInModal.id}, {currentObjectToBeViewedInModal.detail}
						</span>
						<div>
							<h1 className="text-2xl">Confirm kinikan</h1>
						</div>
						<div className="flex items-center gap-x-4 mt-4">
							<button className="flex-grow py-2.5 rounded-md bg-slate-300" type="button" onClick={() => setItemModal(false)}>
								Cancel
							</button>
							<button
								onClick={() => setIsChecked(currentObjectToBeViewedInModal.id)}
								className="flex-grow py-2.5 rounded-md bg-[#172337] text-white"
								type="button">
								Confirm
							</button>
						</div>
					</div>
				</ModalComponent>
			) : null}
		</div>
	);
}

export default Assests;
