import { FiUsers, FiCalendar, FiUserCheck, FiUserPlus, FiSettings, FiActivity, FiHome } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { TbHelp } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";
import { MdOutlineWebAsset } from "react-icons/md";
import { RiBuilding2Line } from "react-icons/ri";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

export const DashboardSideNavLinks = [
	{
		id: uuidv4(),
		icon: FiHome,
		name: "Home",
		path: "/dashboard/home",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiUserCheck,
		name: "Attendance",
		path: "/dashboard/attendance",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiUserPlus,
		name: "Add Staff",
		path: "/dashboard/add-staff",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiUsers,
		name: "Staffs",
		path: "/dashboard/all-staff",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiCalendar,
		name: "Schedule",
		path: "/dashboard/schedule",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiActivity,
		name: "Activities",
		path: "/dashboard/activities",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: MdOutlineWebAsset,
		name: "Assets",
		path: "/dashboard/assets",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: AiOutlineFundProjectionScreen,
		name: "Procurements",
		path: "/dashboard/procurements",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: RiBuilding2Line,
		name: "Departments",
		path: "/dashboard/departments",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: TbHelp,
		name: "Help",
		path: "/dashboard/faq",
		accountType: "both",
	},
	{
		id: uuidv4(),
		icon: FiSettings,
		name: "System Settings",
		path: "/dashboard/systemSettings",
		accountType: "both",
	},
];
