import React from 'react';

function SuccessStep() {
  return (
    <div className="SuccessStep">
      <h2>🎉 Welcome to Urbex Africa, Green</h2>

      <p>
        I’m Siri and I wanted to be the first to welcome you to Urbex Africa -
        the reliable Contractor platform built for you.
      </p>

      <p>
        We built Urbex Africa, to make it easy for you to bid for projects
        (contracts), with an escrow system to help you in safe payment by
        clients.
      </p>

      <p>
        So go ahead, bid for your first projects, and if you need help with
        anything here, shoot me an email at siri@urbex.africa
      </p>
    </div>
  );
}

export default SuccessStep;
